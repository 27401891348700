<template>
<div class="marketing-upgrade">
    <div class="marketing-upgrade-content">
        <div class="marketing-title">
            营销数字化升级，从现在开始
        </div>
        <div class="marketing-button" @click="goExperience">
            立即体验
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'marketing-upgrade',
    props: {
    },
    data () {
        return {
        }
    },
    methods: {
        // 跳转立即体验
        goExperience() {
            window._hmt.push(['_trackEvent', 'button','click', '首页主体底部立即体验按钮']);
             this.$router.push({ name: 'reservation',params:{type: 1,} });
        }
    }
}
</script>
<style lang="scss" scoped>
.marketing-upgrade{
    height: 188px;
    background: url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/marketing-upgrade-background.png');
    background-size: cover;
    .marketing-upgrade-content{
        margin: 0 auto;
        width: 1200px;
        padding: 40px 0 100px;
        // height: 718px;
        text-align: center;
        .marketing-title{
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        .marketing-button{
            display: inline-block;
            width: 132px;
            height: 48px;
            background: #FFFFFF;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3777F6;
            line-height: 48px;
            cursor: pointer;
            // text-align: center;
            margin-top: 20px;
            &:hover{
                opacity: .8;
            }
        }
    }
}

// 移动端布局
@import "@/views/home/min-home";
</style>
  