<template>
  <div class="culture-page">
    <div class="left">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="企业价值观" name="value">
          <div class="box">
            <div class="box-item">
              <h5>客户第一</h5>
              <p>为客户创造真实价值</p>
              <p>不辜负每一份信任</p>
            </div>
            <div class="box-item">
              <h5>主人翁精神</h5>
              <p>独立思考不唯上</p>
              <p>因为信任，所以简单</p>
              <p>目标一致，相互帮助，共同达成</p>
            </div>
            <div class="box-item">
              <h5>追求卓越</h5>
              <p>极致专注</p>
              <p>做难而正确的事情</p>
              <p>the best or nothing</p>
            </div>
            <div class="box-item">
              <h5>求真务实</h5>
              <p>讲真话，干实事</p>
              <p>先做60分，再做100分</p>
              <p>只为成功找方法，不为失败找理由</p>
            </div>
            <div class="box-item">
              <h5>开放谦逊</h5>
              <p>开卷考试</p>
              <p>Stay hungry, Stay foolish</p>
            </div>
            <div class="box-item">
              <h5>坦诚清晰</h5>
              <p>直面冲突、坦诚沟通、解决分歧</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业使命" name="mission">
          <div class="box">
            <h5>更美好的地产，更美好的居住。</h5>
          </div>
        </el-tab-pane>
        <el-tab-pane label="品牌愿景" name="vision">
          <div class="box">
            <h5>打造地产营销新基建，构建数字商业新文明。</h5>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="right">
      <img :src="images[activeName]['url']" :alt="images[activeName]['name']" />
    </div>
  </div>
</template>

<script>

export default {
  name: "AboutCulture",

  data() {
    return {
      activeName: "value",
      images: {
        value: {
          name: "企业价值观",
          url: "https://cdn.ideamake.cn/sw-pc/images/about-intro-02.jpeg",
        },
        mission: {
          name: "企业使命",
          url: "https://cdn.ideamake.cn/sw-pc/images/about-intro-03.jpeg",
        },
        vision: {
          name: "品牌愿景",
          url: "https://cdn.ideamake.cn/sw-pc/images/about-intro-04.jpeg",
        },
      },
    };
  },
  methods: {
      handleClick() {
        window._hmt.push(['_trackEvent', 'button','click', `关于我们关于思为企业文化${this.images[this.activeName]['name']}主体tab`]);
      }
    }
};
</script>

<style lang="scss" scoped>
.culture-page {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  display: flex;
  .left {
    width: 50%;
    padding-right: 50px;
    .box {
      display: flex;
      flex-wrap: wrap;
      margin: 16px 0;
      &-item {
        width: 50%;
        text-align: left;
        height: 140px;
        font-size: 14px;
        color: #5a5c60;
      }
      h5 {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        color: #2D2E30;
      }
    }
  }
  .right {
    width: 50%;
    img {
      width: 100%;
      border-radius: 2px;
    }
  }
  ::v-deep .el-tabs__item {
    color: #5a5c60;
    &.is-active {
      color: #3777f6;
      font-weight: 500;
    }
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #3777f6;
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>