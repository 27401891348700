<template>
  <div class="digital-page">
    <h3 class="title">数字化营销领导者</h3>
    <div class="container">
      <div class="item">
        <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-01.png" alt="地产营销云领跑者" />
        <p>地产营销云领跑者</p>
      </div>
      <div class="item">
        <img
          src="https://cdn.ideamake.cn/sw-pc/images/marketing-02.png"
          alt="百强国央企的共同选择"
        />
        <p>百强国央企的共同选择</p>
      </div>
      <div class="item">
        <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-03.png" alt="全周期闭环解决方案" />
        <p>全周期闭环解决方案</p>
      </div>
      <div class="item">
        <img src="https://cdn.ideamake.cn/sw-pc/images/marketing-04.png" alt="行业内容标准定义者" />
        <p>行业内容标准定义者</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.digital-page {
  width: 100%;
  height: 280px;
  background: #fff;
  margin-top: 70px;
  box-sizing: border-box;
  text-align: center;
  
  .title {
   
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    .item {
      font-size: 20px;
      font-weight: 500;
      color: #2d2e30;
      & img {
        display: inline-block;
        width: 48px;
        height: 48px;
      }
      p {
          margin-top: 20px;
      }
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>