<template>
  <div class="address-page address">
    <h3 class="title">我们的销售、服务、运营网络</h3>
    <div class="container">
      <img
        class="address"
        src="https://cdn.ideamake.cn/sw-pc/images/address-footer.png"
        alt="我们的销售、服务、运营网络"
      />
      <div class="email">
        <div class="item min-phone">
          <h6 class="item-name">北京</h6>
          <p>地址：北京市朝阳区酒仙桥路4号正东集团院内A17号一层0104室</p>
        </div>
        <div class="item min-phone">
          <h6 class="item-name">上海</h6>
          <p>地址：上海市闵行区申长路988弄5号楼3楼321室</p>
        </div>
        <div class="item min-phone">
          <h6 class="item-name">深圳</h6>
          <p>地址：深圳市南山区粤海街道高新区社区科技南一路28号达实智能大厦35层</p>
        </div>
        <div class="item min-phone">
          <h6 class="item-name">成都</h6>
          <p>地址：成都市高新区盛安街401号1栋2单元8层827号</p>
        </div>
        <div class="item">
          <h6 class="item-name">商务、市场及媒体合作</h6>
          <a @click="marketing" href="mailto:marketing@ideamake.cn">marketing@ideamake.cn</a>
        </div>
        <div class="item">
          <h6 class="item-name">人才加入</h6>
          <a @click="mailto" href="mailto:hr@ideamake.cn">hr@ideamake.cn</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'AboutAddress',
    methods: {
      marketing () {
        window._hmt.push(['_trackEvent', 'button','click', '关于我们底部商务、市场及媒体合作跳转链接']);
      },
      mailto () {
        window._hmt.push(['_trackEvent', 'button','click', '关于我们底部人才加入跳转链接']);
      },
    }
};
</script>

<style lang="scss" scoped>
.address-page {
  width: 100%;
  min-height: 380px;
  background: #fff;
  padding-top: 80px;
  box-sizing: border-box;
  text-align: center;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    .address {
      display: block;
      margin: 0 auto;
    }
    .email {
      display: flex;
      margin:30px 0 80px;
      justify-content: center;
      .item {
        width: 384px;
        height: 104px;
        background: #ffffff;
        padding: 18px;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        text-align: left;
        margin-right: 16px;
        &.min-phone{
          display: none;
        }
        &-name {
          font-size: 16px;
          font-weight: 500;
          color: #2d2e30;
          margin-bottom: 16px;
        }
        a {
            color: #3777F6;
        }
      }
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>