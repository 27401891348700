// 该顺序不能动，是底部按钮下一个方案的顺序依据
export const dataList = [
  {
    pathUrl: '/solution/online-hall',
    pathName: '线上展厅',
    title: '快速构建线上营销阵地，打造流畅的买房卖房体验',
    dataInfo: [
      {
        direction: 'left',
        title: '小程序线上展厅',
        link: '/index',
        list: [
          '全方位小程序线上展厅，移动看房、购房一体化',
          '更懂用户使用习惯和需求，实现品牌价值的极致展示',
          '销售工作台10+种拓客物料，快速覆盖拓客全场景',
        ],

        image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/online-hall-one.png',
      },
      {
        direction: 'right',
        title: '对客多端统一',

        link: '/index',
        list: [
          '微信、抖音多端展厅统一配置对客，项目、活动一键全域推广',
          '组件化快速配置，实现一个展示端多端口快速复制',
        ],

        image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/online-hall-two.png',

      },
      {
        direction: 'left',
        title: '千人千面',

        link: '/index',
        list: [
          '面向多个核心角色呈现不同设计，客户/经纪人/销售一端多面',
          '千城千面，基于城市定位，呈现不同城市首页及项目详情页',
        ],
        image:  'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/online-hall-three.png',
      },
    ],
  },
  {
    pathUrl: '/solution/precise-customer',
    pathName: '全域获客',
    title: '内容驱动，实现策销渠一体，千人千面提升全渠道获客体验',
    dataInfo: [
      {
        direction: 'left',
        title: '全渠道投放',
        subtitle: '投放获客的一站式提效解决方案',
        link: '/index',
        list: [
          '主流投放平台全覆盖，打通公域-私域获客通路',
          '线索统一收口，一站式管理所有客源',
          '效果智能分析，提升投放精准度及费效',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-one.png',
      },
      {
        direction: 'right',
        title: '抖音获客',
        subtitle: '更智能的抖音流量经营解决方案',
        link: '/index',
        list: [
          '直播促进公域触达，活动促成客户转化',
          '短视频+线上楼盘小程序，从“兴趣”到“意向”',
          '抖音全场景流量位，一站式品牌内容呈现',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-two.png',
      },
      {
        direction: 'left',
        title: '活动获客',
        subtitle: '优秀活动一键复用，玩法多样快速组合',
        link: '/index',
        list: [
          '7000+高品质活动模板，一键套用快速获客',
          '5级玩法嵌套满足复杂营销需求，契合95%的营销节点',
          '“拖-拉-拽”快速配置活动，上线效率提升4倍以上',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-three.png',
      },
      {
        direction: 'right',
        title: '直播获客',
        subtitle: '流量营销新模式，“面对面”交互获客',
        link: '/index',
        list: [
          '电子沙盘投射直播，物料挂载便捷展示',
          '自动化人像抠图，沉浸式讲解互动',
          '物料、商品后台聚合管理，直播间灵活配置',
          '直播数据及线索汇总查看，互动效果智能分析',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/accurate-customer-four.png',
      },
      {
        direction: 'left',
        title: '内容获客',
        subtitle: '内容驱动的智能营销引擎',
        link: '/index',
        list: [
          '多样化传播物料，覆盖拓客全场景',
          '一物一码多途径管理，便捷溯源优化',
          '细颗粒度数据采集，获取轨迹挖掘潜客',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/accurate-customer-five.png',
      },

    ],
  },
  {
    pathUrl: '/solution/channel-manage',
    pathName: '渠道管理',
    title: '强化企业渠道管控能力，赋能全渠道高效运转',
    dataInfo: [
      {
        direction: 'left',
        title: '自渠管理',
        subtitle: '精细化自渠管理，提升规模获客转化',
        link: '/index',
        list: [
          '自定义搭建团队，多种形式邀请成员加入协作',
          '提供拓客码等多样化推客工具，助力行销拓客',
          '高效规范建立客户报备、跟进全流程规则，精细化管理洞察',
          '自渠拓客数据多维统计，智能分析提升转化',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-one.png',
      },
      {
        direction: 'right',
        title: '分销管理',
        subtitle: '规范分销机构运作流程，提升分销管理效率',
        link: '/index',
        list: [
          '支持多分销机构，统一入口集中管理',
          '不同渠道对应不同规则，灵活设定高效合作',
          '从报备、判客到跟进，透明渠道流程防飞单',
          '渠道数据智能分析，助力优化成交结构',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-two.png',
      },
      {
        direction: 'left',
        title: '全民营销',
        subtitle: '助力规模化低成本获客',
        link: '/index',
        list: [
          '建立经纪人工作台，报备到结佣全流程可视化',
          '搭建经纪人自裂变网络，实现二级分佣',
          '在线快速结佣，激发经纪人推客动力',
          '积分商城激励，长期运营阵地提升粘性',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-three.png',
      },
      {
        direction: 'right',
        title: '渠道判客',
        subtitle: '更快速智能的渠道判客机制',
        link: '/index',
        list: [
          '灵活设定报备到认购全阶段字段规则，辅助快速溯源',
          '报备即可实时判客，清晰归属及时跟进',
          '支持发起判客申诉，有效调解归属纠纷',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-four.png',
      },
      {
        direction: 'left',
        title: '渠道风控',
        subtitle: '多重核验预警提示，守好每一道飞单防线',
        link: '/index',
        list: [
          '监控推荐到成交全流程数据，实现8大风险类型校验',
          '客户到访过程及轨迹全程记录，精准判别风险',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-five.png',
      },
      {
        direction: 'right',
        title: '在线快结佣',
        subtitle: '比渠道公司更快、更高效的结佣方式',
        link: '/index',
        list: [
          '线上一键发放，7天快速结佣',
          '全流程可视化，实时跟踪进度',
          '到手佣金更多，推客更有动力',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-manage-six.png',
      },
    ],
  },
  {
    pathUrl: '/solution/intelligent-operate',
    pathName: '客户培育',
    title: '千万级存量客户分层及自动化运营，全面把握意向加速客户转化',
    dataInfo: [
      {
        direction: 'left',
        title: '全渠道触点',
        subtitle: '覆盖全渠道的有效触达和内容互动',
        link: '/index',
        list: [
          '覆盖抖音、微信、企微等公私域，全渠道客户统一管理',
          '小程序触点激活潜在客户，实时性识别意向客户',
          '一体化内容运营，满足多角色内容创作和共享，促进有效互动',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-one.png',
      },
      {
        direction: 'right',
        title: '客户台账',
        subtitle: '客户全生命周期管理平台',
        link: '/index',
        list: [
          '多渠道线索一站集成，全方位识别客户',
          '多维度标签画像，定向圈选精准运营',
          '自定义线索视图，客户分层运营',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-two.png',
      },
      {
        direction: 'left',
        title: '营销自动化',
        subtitle: '基于客户行为洞察的智能培育',
        link: '/index',
        list: [
          '整合多营销渠道触达客户，实现关键场景自动牵引 ',
          '通过内容物料获取访问轨迹，自动描摹客户画像',
          '基于客户属性细分定向，精准筛选目标客群',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/operations-four.png',
      },
      {
        direction: 'right',
        title: '意向识别',
        subtitle: '精准识别客户意向，辅助营销决策',
        link: '/index',
        list: [
          '基于任务系统+AI云Call，智能快捷识别线索意向',
          '洞悉客群属性和行为，精准预测客户生命周期阶段',
          '基于全渠道数据埋点，生成360度描摹，还原客户真实画像',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-three.png',
      },
      {
        direction: 'left',
        title: 'AI Call客',
        subtitle: '智能外呼高效识别与盘活客户',
        link: '/index',
        list: [
          '简单设置呼叫任务，批量处理上万单沉睡客户',
          '快速筛选客户意向，价值客户一键报备',
          '减少硬件及人力投入，大大降低洗客成本',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/operations-five.png',
      },
 
    ],
  },
  {
    pathUrl: '/solution/smart-scene',
    pathName: '智慧案场',
    footerSubTitle: '案场服务流程标准化，强化销售过程管控',
    title: '案场服务流程标准化，完成线上交易闭环，强化销售过程管控',
    dataInfo: [
      {
        direction: 'left',
        title: '电子签到',
        subtitle: '更规范、更高效的案场接待模式',
        link: '/index',
        list: [
          '统一来访入口，快速完成全渠道客户登记及判客',
          '无归属客户自动排序，手机端实时通知对应置业顾问',
          '自动关联案场跟客模块，避免漏客藏客',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-one.png',
      },

      {
        direction: 'right',
        title: '智能工牌',
        subtitle: '一线销讲看得到，案场盘客更高效',
        link: '/index',
        list: [
          'AI还原更真实客户画像，盘客更高效 ',
          '复制销冠能力模型，让优秀销售经验充分流淌',
          '总结分析客户关注点和敏感点，销讲策略迭代升级',
          '自动匹配用户，开机即用流畅体验，极大提升录音覆盖率',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-six.png',
      },

    ],
  },

  {
    pathUrl: '/solution/transaction-manager',
    pathName: '交易管理',
    title: '低成本数字交易管理系统，助力交易各环节智慧升级',
    dataInfo: [

      {
        direction: 'left',
        title: '房源管理',
        subtitle: '在线轻松管理所有房源',
        link: '/index',
        list: [
          '房型/楼层/个户信息细分呈现，房源详情清晰呈现',
          '楼盘销售进展直观查看，实时调整销控策略',
          '房源定/调价方案快速制定，折扣方案轻松配置',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-two.png',
      },
      {
        direction: 'right',
        title: '线上交易',
        subtitle: '线上完成购房全动作，在家也能轻松购房',
        link: '/index',
        list: [
          '在线认筹、认购轻松完成客户意向锁定，省去人工录入成本',
          '客户线上实名认证，快速完成线上合同签署',
          '可视化跟踪每日交易情况，快捷查看交易信息',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/field-three.png',
      },
      {
        direction: 'left',
        title: '在线开盘',
        subtitle: '解决传统开盘所有问题，快速提升开盘效率和规模',
        link: '/index',
        list: [
          '流程简单，极速筹备，最快一天即可开盘',
          '足不出户，微信选房，一键开抢效率飙升',
          '数据看板，实时查看，去化情况一目了然',
          '安全稳定，系统强大，为全流程保驾护航',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-five.png',
      },
      {
        direction: 'right',
        title: '回款管理',
        subtitle: '回款进度实时掌握，回款效率快速提升',
        link: '/index',
        list: [
          '自动生成应收款项，快速生成催款任务 ',
          '欠款催收提醒，加快回款效率',
          '快速收款，自动生成收款单据',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-four.png',
      },
 
      {
        direction: 'left',
        title: '审批管理',
        subtitle: '各类业务审批在线通关',
        link: '/index',
        list: [
          '自由定义流程走向，多管理员共同协作',
          '各组织间互不干扰，多条审批并行流转',
          '集中管控流程数据，审批记录条条可查',
          '层层节点环环相扣，流转进度实时通知',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/field-seven.png',
      },
    ],
  },

  {
    pathUrl: '/solution/customer-precipitate',
    pathName: '社群运营',
    title: '结合活动+权益，通过场景引导驱动全民转化，提升客户资源利用率',
    footerSubTitle: '多场景驱动全民转化，快速提升客户资源利用率',
    dataInfo: [
      {
        direction: 'left',
        title: '业主服务',
        subtitle: '圈层增长转化解决方案',
        link: '/index',
        list: [
          '客户来源信息打标，快速分配精细化运营',
          '多种智能化群运营能力，自动触达定向客群',
          '群聊数据可视化分析，有效优化运营效率',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/precipitate-one.png',
      },
      {
        direction: 'right',
        title: '积分体系',
        subtitle: '精准激励，活跃熟客',
        link: '/index',
        list: [
          '储值、优惠、会员等多权益互联，扩大流量来源',
          '分阶段储备积分兑换礼品，提高客户活跃度',
          '分享裂变积分激励，低成本拉新促活',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/precipitate-two.png',
      },
      {
        direction: 'left',
        title: '营销活动',
        subtitle: '优秀活动一键复用，玩法多样快速组合',
        link: '/index',
        list: [
          '7000+高品质活动模板，一键套用快速获客',
          '5级玩法嵌套满足复杂营销需求，契合95%的营销节点',
          '“拖-拉-拽”快速配置活动，上线效率提升4倍以上',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/precipitate-three.png',
      },
      {
        direction: 'right',
        title: '业主老带新',
        subtitle: '助力规模化低成本获客',
        link: '/index',
        list: [
          '建立经纪人工作台，报备到结佣全流程可视化 ',
          '搭建经纪人自裂变网络，实现二级分佣',
          '在线快速结佣，激发经纪人推客动力',
          '积分商城激励，长期运营阵地提升粘性',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/precipitate-four.png',
      },
    ],
  },
  {
    pathUrl: '/solution/data-insight',
    pathName: '管理驾驶舱',
    title: '汇总全域数据可视化查看，助力企业以数据驱动业绩增长',
    footerSubTitle: '全域数据可视化汇总查看，助力企业以数据驱动业绩增长',
    dataInfo: [
      {
        direction: 'left',
        title: '业绩解码',
        subtitle: '为一线提供真实业务指导意义的营销BI',
        link: '/index',
        list: [
          '集成全域数据，打造1+3可视化分析平台，支撑营销决策',
          '数据联动过滤及下钻，分析系统性强',
          '一次建模，长期复用，数据处理效率大大提升',
          '满足团队协作、项目管理、数据协作等多重场景',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/insight-one.png',
      },
      {
        direction: 'right',
        title: '客户分析平台',
        subtitle: '深入业务场景的一站式用户行为分析平台',
        link: '/index',
        list: [
          '打通全渠道数据，以One ID统一身份，构建面向业务的用户标签体系',
          '定义用户生命周期及识别特征，搭建完整用户画像，指导营销策略',
          '分渠道、分顾问、分行为查看不同分群客户转化情况，反推转化提升',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/insight-two.png',
      },
      {
        direction: 'left',
        title: '数据报表',
        subtitle: '自助式的BI报表平台',
        link: '/index',
        list: [
          'N个多样化报表模板，营销链路多节点支撑业务决策',
          '灵活拖拽式操作，自由添加、管理数据，大幅降低分析难度，提高汇报沟通效率',
          '各类型数据自定义导出，查询结果图表化，释放分析能力',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/insight-three.png',
      },
    ],
  },
  {
    pathUrl: '/solution/sales-empowerment',
    pathName: '销售赋能',
    title: '整合公私域平台，打造超级销售',
    dataInfo: [
      {
        direction: 'left',
        title: '公域拓新',
        subtitle: '连接流量平台为销售赋能',
        link: '/index',
        list: [
          '抖音账号授权互通，视频、获客数据聚合查看',
          '官方抖音小程序内容一键挂载',
          '留电客户资源同步，来源清晰可追溯',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/empowerment-one.png',
      },
      {
        direction: 'right',
        title: '智能助手',
        subtitle: '让销售进程跟进更高效',
        link: '/index',
        list: [
          'APP通知强提醒，提升VR带看接通率',
          '客户智能打标，快速分类管理',
          '应用内一键call客，快速操作响应',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/empowerment-two.png',
      },
      {
        direction: 'left',
        title: '私域运营',
        subtitle: '助力销售深度挖掘商机',
        link: '/index',
        list: [
          '自动描摹客户画像，一客一策精细运营',
          '内容物料一键转发私域，快速识别兴趣客户',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/empowerment-three.png',
      },
      {
        direction: 'right',
        title: '客户管理',
        subtitle: '群客户的定向智能运营',
        link: '/index',
        list: [
          '多平台线索集成，建立客户视图分层运营',
          '打通全域沟通数据，自动完善客户画像',
          'chatGPT赋能，助力销售高效盘客',
        ],
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/empowerment-four.png',
      },
    ],
  },
  {
    pathUrl: '/solution/visual-display',
    pathName: '视觉展示',
    title: '全新视觉交互体验，打破时空限制，拓宽获客边界',
    dataInfo: [
 
      {
        direction: 'left',
        title: '移动微沙盘',
        subtitle: '可视化场景营销利器',
        link: '/index',
        list: [
          '项目价值点极致演绎，充分满足客户看房需求',
          'iPad端移动案场，联动大屏、一体机，实现便捷展示',
          '移动端扫码分享，实现微信裂变传播',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-one.png',
      },
      {
        direction: 'right',
        title: 'VR带看',
        subtitle: '智能交互的看房黑科技',
        link: '/index',
        list: [
          '远程VR一对一/一对多带看，实时语音互动答疑',
          '全场景沉浸式讲房，炫酷体验强化认同',
          '实时派券关联活动，邀约认筹及时转化',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/visual-two.png',
      },
      {
        direction: 'left',
        title: 'UE虚幻现实案场',
        subtitle: '传统模型与数字信息的完美融合',
        link: '/index',
        list: [
          '城市扫描+GIS技术真实还原城市环境',
          '游戏级别的互动感，带给用户超越真实的场景体验',
          '强大跨平台功能，所有信息一键导入iPad程序',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-three.png',
      },
      {
        direction: 'right',
        title: '云创平台',
        subtitle: '一站式营销物料生产平台',
        link: '/index',
        list: [
          '可视化开发平台，聚焦内容创意，集成流程一目了然',
          '海量优秀案例模板，覆盖多类型地产应用场景',
          '跨平台应用技术，满足平板、大屏、移动端、一体机多平台内容制作',
          '八大内容模块，满足不同营销节点推广需求',
        ],
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-four.png',
      },
    ],
  },
];

export const bannerList = [
  {
    pathUrl: '/solution/online-hall',
    pathName: '线上展厅',
    banner: [
      {
        
        titleDesc: '小程序门户、多端统一、千人千面',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-banner-online-hall.png',
        minImage: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/min-banner-online-hall.png',
        title: '移动售楼处',
        pathTitle: '线上展厅',
        desc: '快速搭建的一站式线上售楼处，小程序全方位呈现项目信息，微信/抖音多端统一对客，千人千面门户集合，为企业提供移动看房、购房一体化解决方案',
        link: '/index',
      },
    ],
  },

  {
    pathUrl: '/solution/precise-customer',
    pathName: '全域获客',
   
    banner: [
      {
        titleDesc: '全渠道覆盖、低费效比、高转化率',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-one.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-one.png',
        title: '全渠道精准获客方案',
        pathTitle: '全域获客',
        desc: '通过广告投放、内容营销、直播引流、老带新激活等功能打通线上线下，实现策销渠一体，帮助企业全域精准获取高质量线索',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/channel-manage',
    pathName: '渠道管理',
    footerTitle: '全渠道高效管理方案',
    banner: [
      {
        titleDesc: '规范管理、精准判客、渠道风控',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-two.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-two.png',
        title: '全渠道高效管理方案',
        pathTitle: '渠道管理',
        desc: '连接内外拓客渠道统一管理，规范渠道对接流程高效判客转化，多种拓客物料便捷分发，渠道数据集成分析，助力企业挖掘最优渠道配比',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/intelligent-operate',
    pathName: '客户培育',
    banner: [
      {
        titleDesc: '一体化管理、营销自动化、智能标签',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-three.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-three.png',
        title: '客户智能培育方案',
        pathTitle: '客户培育',
        desc: '打通多端流量平台统一管理公域线索，自动生成客户标签及画像，一客一策分层自动化运营，助力销售实现强效能运营转化',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/transaction-manager',
    pathName: '交易管理',
    banner: [
      {
        titleDesc: '低成本、易搭建、更先进',
        image:
        'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-banner-transaction-manager.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/min-banner-transaction-manager.png',
        title: '房地产轻量化ERP系统',
        pathTitle: '交易管理',
        desc: '简单易用的轻量化房地产交易管理系统，赋能房源管理、线上交易、在线开盘、快速回款、审批流转，实时掌控交易各环节进度，助力交易效率快速提升',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/smart-scene',
    pathName: '智慧案场',
    banner: [
      {
        titleDesc: '签到溯源、销冠复制、案场提效',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-four.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-four.png',
        title: '智慧案场数字化方案',
        pathTitle: '智慧案场',
        desc: '赋能客户报备-到访-溯源-判客-交易全流程，支持线上到成交闭环精细化管理，帮助企业高效规范案场服务及销售过程，快速实现案场数字化升级',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/customer-precipitate',
    pathName: '社群运营',
    banner: [
      {
        titleDesc: '老带新、口碑传播、裂变增长',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-five.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-five.png',
        title: '社群运营方案',
        pathTitle: '社群运营',
        desc: '基于社群运营场景，灵活运用积分权益+营销活动+全民营销等多重玩法，挖掘核心业主等KOC，以老带新实现裂变级增长，让成交客户成为口碑传播客户',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/data-insight',
    pathName: '管理驾驶舱',
    banner: [
      {
        titleDesc: '最符合业务逻辑，报表统一、灵活生成查看',
        image: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-six.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-six.png',
        title: '数据管控赋能方案',
        pathTitle: '管理驾驶舱',
        desc: '采集汇总全域数据，BI看板可视化呈现成交结构、客户情况、服务流程等多维口径，实时分析自动预警，为前端业务提供决策指导',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/sales-empowerment',
    pathName: '销售赋能',
    banner: [
      {
        titleDesc: '抖音高效拓客、拉通公私域、智能销售助手',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-seven.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-seven.png',
        title: '知屋｜一线销售爱用好用的业务伙伴',
        desc: '连接抖音等流量平台快速公域拓客，多平台客户线索及动态集成，自动画像分层运营，智能助手辅助打标、带看提醒，为一线业务提供更先进的作战工具',
        link: '/index',
      },
    ],
  },
  {
    pathUrl: '/solution/visual-display',
    pathName: '视觉展示',
    banner: [
      {
        titleDesc: '极致展示、实时交互、流畅体验',
        image:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/big-banner-eight.png',
        minImage:
          'https://cdn.ideamake.cn/sw-pc/images/web3.0/min-banner-eight.png',
        title: '一站式可视化的场景营销方案',
        desc: '结合VR、UE等前沿视觉技术，打破时空限制，通过三维场景、VR连接带看为客户提供全新视觉体验，助力企业提升楼盘效率、拓客获客边界',
        link: '/index',
      },
    ],
  },
];
