<template>
  <div ref="menuConent" class="menuConent">
    <div class="menuConent-header">
      <div class="title">多维产品矩阵，全面赋能数字化营销转型</div>
      <div :class="searchFixed ? 'fixed' : ''" class="home-bar">
        <div
          class="home-bar-box"
          :style="searchFixed ? {} : { height: '260px' }"
        >
          <label
            style="position: relative"
            v-for="(item, index) in labelList"
            :key="index"
            :class="{ 'label-active': active === index }"
            :for="item.forName"
            @click="handleClick(index, item.title)"
          >
            <img class="label-img" :src="item.imgSrc" />
            <p class="label-tit">{{ item.title }}</p>
          </label>
        </div>
      </div>
    </div>
    <div class="home-con home-con-big">
      <!-- 首页主体tab内容 -->
      <div ref="homeBarCon" class="target-div">
        <div
          class="home-con-wrapper"
          v-for="(item, index) in list"
          :key="index"
          :class="[{ active: index % 2 === 1 }]"
        >
          <div class="home-con-item home-con-item-big">
            <div v-if="(index + 1) % 2 === 0" class="tab-img-box">
              <img class="first-img" :src="item.imgSrc" alt="" />
            </div>
            <div class="first-word">
              <div class="first-word-big">
                {{ item.title }}
              </div>
              <div class="first-word-middle">{{ item.content }}</div>
              <div class="home-icon-list-box">
                <div
                  v-for="(iconItem, iconIndex) in item.iconList"
                  :key="iconIndex"
                  :class="[
                    'iconList',
                    {
                      active:
                        iconIndex === 1 || iconIndex === 4 || iconIndex === 7,
                    },
                  ]"
                  @click="
                    goSolutionDetailIndex(item.title, iconIndex, iconItem.label)
                  "
                >
                  <div class="icon">
                    <img class="icon-img" :src="iconItem.icon" alt="" />
                    {{ iconItem.label }}
                  </div>
                </div>
              </div>
              <div class="min-phone-tab-img-box">
                <img class="first-img" :src="item.imgSrc" alt="" />
              </div>

              <div class="button-bottom">
                <div
                  class="first-word-big-button"
                  @click="information('客户获取-线上展厅', item.title)"
                  data-agl-cvt="5"
                >
                  免费试用
                </div>
                <div
                  class="first-word-big-button button-detail"
                  @click="goSolutionDetail(item.title)"
                  data-agl-cvt="5"
                >
                  查看详情
                </div>
              </div>
            </div>
            <div v-if="(index + 1) % 2 !== 0" class="tab-img-box">
              <img class="first-img" :src="item.imgSrc" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swTrackerClickReport } from '@/tracker.js';

export default {
  name: 'menu-Conent',
  props: {},
  data() {
    return {
      active: 0,
      searchFixed: 0,
      offsetTop: 0,
      homeConHeight: 0, // 页面内容高度
      labelList: [
        {
          forName: 'first',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/first-top-online-hall.png',
          title: '线上展厅',
        },

        {
          forName: 'second',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/customer-acquisition-type.png',
          title: '全域获客',
        },
        {
          forName: 'third',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management.png',
          title: '渠道管理',
        },
        {
          forName: 'four',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/intelligent-operations.png',
          title: '客户培育',
        },
        {
          forName: 'five',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/case-management-type.png',
          title: '智慧案场',
        },
        {
          forName: 'six',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/first-top-transaction-manager.png',
          title: '交易管理',
        },
        {
          forName: 'seven',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation.png',
          title: '社群运营',
        },

        {
          forName: 'eight',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/customer-operations-type.png',
          title: '管理驾驶舱',
        },
        {
          forName: 'nine',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/sales-empowerment.png',
          title: '销售赋能',
        },
        {
          forName: 'ten',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/visual-marketing-type.png',
          title: '视觉展示',
        },
      ],
      list: [
        {
          forName: 'first',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/first-main-online-hall.png',
          title: '线上展厅',
          iconList: [],
          content:
            '快速搭建的一站式线上售楼处，小程序全方位呈现项目信息，微信/抖音多端统一配置对客，千人千面门户集合，为企业提供移动看房、购房一体化解决方案',
        },
        {
          forName: 'second',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-photo.png',
          title: '全域获客',
          iconList: [
            {
              label: '全渠道投放',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-one.png',
            },
            {
              label: '抖音获客',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-two.png',
            },
            {
              label: '活动获客',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-three.png',
            },
            {
              label: '直播获客',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-four.png',
            },
            {
              label: '内容获客',
              code: '5',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-acquisition-five.png',
            },
          ],
          content:
            '通过广告投放、内容营销、直播引流、老带新激活等功能打通线上线下，实现策销渠一体，帮助企业全域精准获取高质量线索',
        },
        {
          forName: 'third',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/intelligent-operations-photo.png',
          title: '渠道管理',
          iconList: [
            {
              label: '自渠管理',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-one.png',
            },
            {
              label: '分销管理',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-two.png',
            },
            {
              label: '全民营销',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-three.png',
            },
            {
              label: '渠道判客',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-four.png',
            },
            {
              label: '渠道风控',
              code: '5',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-five.png',
            },
            {
              label: '在线快结佣',
              code: '6',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-six.png',
            },
          ],
          content:
            '全渠道高效管理方案，连接内外拓客渠道统一管理，规范渠道对接流程高效判客转化。多种拓客物料便捷分发，渠道数据集成分析，助力房企优化成交结构',
        },
        {
          forName: 'four',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/channel-management-photo.png',
          title: '客户培育',
          iconList: [
            {
              label: '全渠道触点',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/case-field-one.png',
            },
            {
              label: '客户台账',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/case-field-two.png',
            },
            {
              label: '意向识别',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/case-field-three.png',
            },
            {
              label: '营销自动化',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/case-field-four.png',
            },
            {
              label: 'AI Call客',
              code: '5',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/case-field-five.png',
            },
          ],
          content:
            '客户全周期智能培育方案，打通多端流量平台统一管理公域线索，自动生成客户标签及画像，一客一策分层自动化运营，助力销售实现强效能运营转化',
        },
        {
          forName: 'five',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/case-field-photo.png',
          title: '智慧案场',
          iconList: [
            {
              label: '电子签到',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-one.png',
            },

            {
              label: '智能工牌',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-seven.png',
            },
          ],
          content:
            '智慧案场数字化方案，赋能客户报备-到访-溯源-判客-交易全流程，支持线上到成交闭环精细化管理，帮助企业高效规范案场服务及销售过程，快速实现案场数字化升级',
        },
        {
          forName: 'six',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/first-main-transaction-manager.png',

          title: '交易管理',
          iconList: [
            {
              label: '房源管理',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-two.png',
            },

            {
              label: '线上交易',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-four.png',
            },

            {
              label: '在线开盘',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-seven.png',
            },

            {
              label: '回款管理',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-five.png',
            },

            {
              label: '审批管理',
              code: '5',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/smart-case-eight.png',
            },
          ],
          content:
            '简单易用的轻量化房地产交易管理系统，赋能房源管理、线上交易、在线开盘、快速回款、审批流转，实时掌控交易各环节进度，助力交易效率快速提升',
        },
        {
          forName: 'seven',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation-photo.png',
          title: '社群运营',
          iconList: [
            {
              label: '业主服务',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation-one.png',
            },
            {
              label: '积分体系',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation-two.png',
            },
            {
              label: '营销活动',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation-three.png',
            },
            {
              label: '业主老带新',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/customer-sedimentation-four.png',
            },
          ],
          content:
            '基于社群运营场景，灵活运用积分权益+营销活动+全民营销等多重玩法，挖掘核心业主等KOC，以老带新实现裂变级增长，让成交客户成为口碑传播客户',
        },
        {
          forName: 'eight',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-display-photo.png',
          title: '管理驾驶舱',
          iconList: [
            {
              label: '业绩解码',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/data-insight-one.png',
            },
            {
              label: '客户分析平台',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/data-insight-two.png',
            },
            {
              label: '数据报表',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/data-insight-three.png',
            },
          ],
          content:
            '数据管控赋能方案，采集汇总全域数据，BI看板可视化呈现成交结构、客户情况、服务流程等多维口径，实时分析自动预警，为前端业务提供决策指导',
        },
        {
          forName: 'nine',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/sales-empowerment-photo.png',
          title: '销售赋能',
          iconList: [],
          content:
            '知屋，一线销售爱用好用的业务伙伴。连接抖音等流量平台快速公域拓客，多平台客户线索及动态集成，自动画像分层运营，智能助手辅助自动打标、带看提醒，为一线营销业务提供更先进的作战工具',
        },
        {
          forName: 'ten',
          imgSrc:
            'https://cdn.ideamake.cn/sw-pc/images/web3.0/data-insight-photo.png',
          title: '视觉展示',
          iconList: [
            {
              label: '移动微沙盘',
              code: '1',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-display-one.png',
            },
            {
              label: 'VR带看',
              code: '2',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-display-two.png',
            },
            {
              label: 'UE虚幻现实案场',
              code: '3',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-display-three.png',
            },
            {
              label: '云创平台',
              code: '4',
              icon: 'https://cdn.ideamake.cn/sw-pc/images/web3.0/visual-display-four.png',
            },
          ],
          content:
            '一站式可视化场景营销解决方案，结合VR、UE等前沿视觉技术，打破时空限制，通过三维场景、VR连线带看为客户提供全新视觉体验，助力企业提升楼盘效率、拓宽获客边界',
        },
      ],
      isMobile: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    active: {
      handler() {
        const fixedDom = document.querySelector('.fixed');
        // 最外层包裹住item，负责滚动，如有.fixed则说明固定在顶部
        if (fixedDom && this.isMobile) {
          // 单个tab
          let activeNode = document.querySelector('.menuConent .label-active');
          // 元素距离滑块左边距离  -  居中时元素到窗口左边距离  =  滚动条滑动的距离
          let scrollWidth =
            activeNode.offsetLeft -
            (document.body.clientWidth - activeNode.clientWidth) / 2;

          fixedDom.scrollLeft = scrollWidth;
        }
      },
    },
  },
  mounted() {
    let res = this;
    this.$nextTick(() => {
      let homeBarCon = this.$refs.homeBarCon;
      let menuConent = this.$refs.menuConent;
      window.onresize = function () {
        res.offsetTop = homeBarCon.offsetTop;
      };
      window.addEventListener('scroll', this.throttle(this.handleScroll, 200));
      this.judgeIsMobile();
      setTimeout(() => {
        // 吸顶元素到top的距离
        this.offsetTop = homeBarCon.offsetTop;
        this.homeConHeight = menuConent.offsetHeight;
      }, 500);
    });
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    judgeIsMobile() {
      const width = document.body.clientWidth || window.innerWidth;
      this.isMobile = width <= 980; // 如果可视区域小于980，则认为是手机设备
    },

    // 点击tab切换
    handleClick(i, title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体${title}按钮`,
      ]);
      // this.active = i
      const index = Number(i);
      const lis = document.querySelectorAll('.home-con-wrapper');
      this.scrollAnimate(lis[index].offsetTop - 140);
    },
    // 滚动动画
    scrollAnimate(scrollHeight) {
      window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      });
    },
    throttle(fn, delay) {
      let open = true;
      return function () {
        if (open == true) {
          open = false;
          setTimeout(() => {
            fn();
            open = true;
          }, delay);
        }
      };
    },
    information(pathModule, title) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体${title}免费试用按钮`,
      ]);
      swTrackerClickReport('sw_pc_free_trial', pathModule, '思为科技首页');
      this.$store.commit('doStayConsultation');
      this.$router.push({ name: 'reservation', params: { type: 1 } });
    },
    // 跳转解决方案对应详情页
    goSolutionDetail(solutionTitle) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体${solutionTitle}了解详情按钮`,
      ]);
      this.$router.push({ name: solutionTitle });
    },
    // 跳转解决方案对应详情页特定模块
    goSolutionDetailIndex(title, index, label) {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `首页主体${title}${label}按钮`,
      ]);
      this.$router.push({ name: title, query: { index } });
    },
    handleScroll() {
      // 得到页面滚动的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断页面滚动的距离是否大于吸顶元素的位置
      // 吸顶元素到top的距离
      this.$nextTick(() => {
        this.searchFixed =
          scrollTop > this.offsetTop - 180 &&
          scrollTop < this.offsetTop + this.homeConHeight - 400;
      });
      const lis = document.querySelectorAll('.home-con-wrapper');
      let activeIndex = 0;
      if (window.pageYOffset < lis[1].offsetTop - 140) {
        activeIndex = 0;
      } else if (window.pageYOffset < lis[2].offsetTop - 140) {
        activeIndex = 1;
      } else if (window.pageYOffset < lis[3].offsetTop - 140) {
        activeIndex = 2;
      } else if (window.pageYOffset < lis[4].offsetTop - 140) {
        activeIndex = 3;
      } else if (window.pageYOffset < lis[5].offsetTop - 140) {
        activeIndex = 4;
      } else if (window.pageYOffset < lis[6].offsetTop - 140) {
        activeIndex = 5;
      } else if (window.pageYOffset < lis[7].offsetTop - 140) {
        activeIndex = 6;
      } else if (window.pageYOffset < lis[8].offsetTop - 140) {
        activeIndex = 7;
      } else if (window.pageYOffset < lis[9].offsetTop - 140) {
        activeIndex = 8;
      } else {
        activeIndex = 9;
      }
      this.active = activeIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
.label-active {
  color: rgb(55, 119, 246);
  &::before {
    content: '';
    height: 3px;
    width: 150px;
    background: #3777f6;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    bottom: -1px;
    // left: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.icon {
  &:hover {
    color: #3777f6 !important;
  }
}

.fixed {
  position: fixed;
  top: 62px;
  width: 100%;
  left: 0;
  z-index: 99;
  background: #fdfdfd;
  .home-bar-box {
    justify-content: flex-start !important;
  }
  box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25);
  & + #radio {
    margin-top: 170px;
  }
  & label {
    height: 62px !important;
    width: 178px !important;
    line-height: 62px !important;
    background: none !important;
    box-shadow: none !important;
    // margin-right: 126px;
    & img {
      display: none;
    }
    &::after {
      content: '';
    }
    &::before {
      width: 58px !important;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
.menuConent {
  background-color: #fff;
  .menuConent-header {
    box-shadow: 8px 8px 20px 0px rgba(43, 71, 115, 0.1);
    padding-top: 80px;
  }
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
    line-height: 40px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 48px;
  }
  .home-bar {
    .home-bar-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      // height: 260px;
      justify-content: space-between;
      // align-items: flex-end;
      flex-wrap: wrap;
    }
    & label {
      display: inline-block;
      // width: 150px;
      width: 20%;
      height: 110px;
      cursor: pointer;
      text-align: center;
      & img {
        width: 64px;
        // height: 70px;
      }
      .label-tit {
        margin-left: 4px;
      }

      @keyframes mymove {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-15px);
        }
        100% {
          transform: translateY(0px);
        }
      }

      &:hover {
        color: #3777f6;
        img {
          animation: mymove 1.5s linear infinite;
        }
      }
    }
  }
  .home-con {
    padding: 0 0 100px;
    padding-bottom: 0;
    height: 718px;
    .home-con-item {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .min-phone-tab-img-box {
        display: none;
      }
      .first-word {
        padding: 70px 0 0 20px;
        .first-word-big {
          color: #2d2e30;
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          margin-bottom: 12px;
        }
        .iconList {
          display: inline-block;
          margin-bottom: 16px;
          .icon {
            cursor: pointer;
            width: 150px;
            height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: #2d2e30;
            line-height: 22px;
            .icon-img {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: -2px;
            }
          }
        }
      }
      .first-img {
        width: 722px;
        height: 448px;
      }
      .first-img1 {
        width: 712px;
        height: 391px;
      }
    }
    .first-img-type {
      width: 64px;
      height: 70px;
      display: inline-block;
    }
    &-wrapper {
      &.active {
        background: #f5f7fa;
      }
    }
  }
  .home-con-big {
    height: 100%;
    .home-con-item-big {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .home-con-item-middle {
      padding-top: 40px;
    }
    .first-word-middle {
      font-size: 16px;
      font-weight: 400;
      color: #5a5c60;
      line-height: 26px;
      width: 425px;
      padding-bottom: 24px;
    }
    .button-bottom {
      display: flex;
      .first-word-big-button {
        width: 106px;
        height: 40px;
        background: #3777f6;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        margin-top: 27px;
        margin-right: 10px;
        &:hover {
          border: 1px solid #74a4f9;
        }
      }
      .button-detail {
        background: #fff;
        color: #3777f6;
        border: 1px solid #3777f6;
        &:hover {
          color: #ffffff;
          // background: #5f92f8;
          background: #b5c9f7;
        }
      }
    }
  }
}

// 移动端布局
@import '../min-home';
</style>
