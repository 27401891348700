<template>
  <div class="address-page honor">
    <h3 class="title">公司荣誉</h3>
    <div class="container">
      <el-carousel :interval="3000" arrow="always">
        <el-carousel-item v-for="(honorLi, index) in carouselList" :key="index">
          <div class="item">
            <div class="item-name" v-for="honorItem in honorLi" :key="honorItem.title">
              <div class="img-box">
                <el-image
                  class="image"
                  :src="honorItem.srcUrl"
                  :fit="honorItem.fit"
                  :alt="honorItem.title"
                  :preview-src-list="[honorItem.srcUrl]"
                ></el-image>
              </div>
              <p>{{ honorItem.title }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 移动端适配 -->
    <div class="container min-phone">
      <div class="honor-box">
        <div class="honor">
          <div class="honor-item" v-for="(honorLi, index) in carouselList" :key="index">
            <div class="item">
              <div class="item-name" v-for="honorItem in honorLi" :key="honorItem.title">
                <div class="img-box">
                  <el-image
                    class="image"
                    :src="honorItem.srcUrl"
                    fit="cover"
                    :alt="honorItem.title"
                    :preview-src-list="[honorItem.srcUrl]"
                  ></el-image>
                </div>
                <p>{{ honorItem.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutHonor",
  data() {
    return {
      carouselList: [
        [ {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/01.png', title: '阿里云产品生态集成认证', fit: 'cover' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/02.jpg', title: '毕马威中国领先地产科技企业50', fit: 'cover' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/03.jpg', title: '中国科学院深圳先进技术研究院元宇宙空间计算联合实验室', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/04.jpg', title: '广东省“专精特新”企业', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/05.jpg', title: '国家高新技术企业认证', fit: 'fill' },
        ],
        [ {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/06.jpg', title: '最佳雇主企业发展奖', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/07.jpg', title: '2021年度（行业）创新企业', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/08.jpg', title: '2021年度（行业）最具投资价值企业', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/09.jpg', title: '36氪2020未来城市峰会年度地产营销智慧新物种', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/10.jpg', title: '克尔瑞CIOC地产数字力科技领军企业', fit: 'fill' },
        ],
        [ {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/11.png', title: '碧桂园凤凰云创新营销联合实验室', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/12.png', title: '腾讯SaaS加速器首期成员', fit: 'fill' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/13.png', title: '深圳高科技高成长20强', fit: 'cover' },
          {srcUrl: 'https://cdn.ideamake.cn/sw-pc/images/honor/14.png', title: '中国地产金砖奖创新技术大奖', fit: 'cover' },
        ]
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.address-page {
  width: 100%;
  min-height: 544px;

  background: #f7f9fb;
  padding-top: 80px;
  box-sizing: border-box;
  text-align: center;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    .item {
      display: flex;
      justify-content: start;
      padding: 0 80px;
      color: #2D2E30;
      text-align: left;
      &-name {
        width: 188px;
        height: 234px;
        padding: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25);
        border-radius: 8px;
        margin-right: 24px;
        font-size: 14px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }
        .img-box {
          width: 164px;
          height: 118px;
          overflow: hidden;
          margin-bottom: 16px;;
        }
        .image {
          width: 164px;
          height: 118px;
        }
        p {
          text-align: center;
        }
      }
    }
     ::v-deep .el-carousel__button {
      display: block;
     
      width: 36px;

      margin: 0 2px;
      
      border: 2px solid #d8d8d8;
      background: #fff;
      position: relative;
    }
     ::v-deep .is-active .el-carousel__button {
     
      border: 2px solid #3777f6;
     
    }
    ::v-deep .el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      width: 50px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25);
      color: #878A90;
      position: absolute;
      top: 40%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      font-size: 24px;
    }
    &.min-phone{
      display: none;
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>