<template>
  <footer class="footer">
    <!-- PC端 -->
    <div class="footer-box">
      <div class="footer-nav-list">
        <div class="nav-list-item">
          <div class="item-tit">产品与解决方案</div>
          <div class="item-list two-item">
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案线上展厅']);" to="/solution/online-hall">线上展厅</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案全域获客']);" to="/solution/precise-customer">全域获客</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案渠道管理']);" to="/solution/channel-manage">渠道管理</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案客户培育']);" to="/solution/intelligent-operate">客户培育</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案智慧案场']);" to="/solution/smart-scene">智慧案场</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案交易管理']);" to="/solution/transaction-manager">交易管理</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案社群运营']);" to="/solution/customer-precipitate">社群运营</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案管理驾驶舱']);" to="/solution/data-insight">管理驾驶舱</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案销售赋能']);" to="/solution/sales-empowerment">销售赋能</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部产品与解决方案视觉展示']);" to="/solution/visual-display">视觉展示</router-link>
            </div>
          </div>
        </div>
        <div class="nav-list-item">
          <div class="item-tit">成功案例</div>
          <div class="item-list">
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部成功案例营销云']);" to="/cases">营销云</router-link>
            </div>
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部成功案例营销云']);" to="/cases">智慧案场</router-link>
            </div>
          </div>
        </div>·
        <div class="nav-list-item">
          <div class="item-tit">关于我们</div>
          <div class="item-list">
            <div class="list-li">
              <router-link onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们关于思为']);" to="/about">关于思为</router-link>
            </div>
            <div class="list-li">
              <a href="https://ideamake.zhiye.com/home" onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部关于我们加入我们']);" target="_blank">加入我们</a>
            </div>
            <!-- <div class="list-li">
              <router-link to="/partner">合作伙伴</router-link>
            </div> -->
          </div>
        </div>
        <!-- <div class="nav-list-item">
          <div class="item-tit">法律信息</div>
          <div class="item-list">
            <div class="list-li">
              <a href="https://www.ideamake.cn/pdf1/服务协议.pdf" target="_blank">服务协议</a>
            </div>
            <div class="list-li">
              <a href="https://www.ideamake.cn/pdf2/隐私政策.pdf" target="_blank">隐私政策</a>
            </div>
          </div>
        </div> -->
        <div class="nav-list-item">
          <div class="item-tit">联系我们</div>
          <div class="item-list">
            <div class="list-li">400-8075-618</div>
            <div class="list-li">关注思为</div>
            <div class="list-li img">
              <img class="wxQRCode-img" src="https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code.png">
            </div>
          </div>
        </div>
      </div>
      <div class="friend-links">
        <div class="links-tit">友情链接</div>
        <div class="links-list">
          <a href="https://ideamake.zhiye.com/home" target="_blank" onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部思为招聘']);" rel="nofollow">思为招聘</a>
          <a href="https://inficloud.ideamake.cn/index.html#/home" target="_blank" onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部思为云创平台']);" rel="nofollow">思为云创平台</a>
          <a href="https://market-cloud.ideamake.cn/admin/index.html#/login" target="_blank" onclick="window._hmt.push(['_trackEvent', 'button','click', '网站底部思为营销云后台管理']);" rel="nofollow">思为营销云管理平台</a>
        </div>
      </div>
      <div class="footer-capy">
        深圳市南山区粤海街道高新区社区科技南一路28号达实智能大厦35层     深圳思为科技有限公司 版权所有 © 2019思为软件
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">粤ICP备14098888号</a>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="min-footer-box">
      深圳市南山区粤海街道高新区社区科技南一路28号达实智能大厦35层 深圳思为科技有限公司 版权所有 © 2019思为软件
      <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">粤ICP备14098888号</a>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'SwFooter',
  data () {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
footer{
  width: 100%;
  padding-top: 44px;
  background: #25272E;
}
.footer-box{
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  .footer-nav-list{
    display: flex;
    // padding-left: 80px;
    justify-content: space-around;
    .nav-list-item{
      .item-tit{
        font-size: 16px;
        color: #fff;
      }
      .item-list{
        margin-top: 20px;
        font-size: 13px;
        line-height: 30px;
        color: #cfd0d6;
        &.two-item{
          width: 200px;
          display: flex;
          flex-wrap: wrap;
          .list-li{
            width: 100px;
          }
        }
        .list-li{
          opacity: .6;
          a{
            color: #cfd0d6;
            display: block;
          }
          &.img{
            opacity: 1;
            .wxQRCode-img{
              margin-top: 10px;
              width: 108px;
              height: 108px;
            }
          }
        }
      }
    }
  }
  .friend-links{
    padding: 30px 0;
    border-top: 1px solid #383838;
    border-bottom: 1px solid #383838;
    margin-top: 30px;
    display: flex;
    font-size: 13px;
    line-height: 30px;
    .links-tit{
      color: #cfd0d6;
      opacity: .6;
    }
    .links-list{
      color: #cfd0d6;
      opacity: .4;
      a {
        color: #cfd0d6;
        margin-left: 35px;
        float: left;
      }
    }
  }
  .footer-capy{
    padding: 20px 0;
    font-size: 13px;
    color: #cfd0d6;
    text-align: center;
    opacity: .4;
    a{
      color: #cfd0d6;
    }
  }
}
.min-footer-box{
  display: none
}
@import "@/assets/scss/min-index";
</style>
