<template>
  <div class="experience">
    <div class="home-con min-phone">
      <div class="title">{{title}}</div>
      <div class="home-con-item">
        <consult-dialog class="consult-top" ref="consult" ></consult-dialog>
        <div class="first-word" style="padding: 76px 0 0 15px;">
          <div class="first-word-big">
            {{minTitle}}
          </div>
          <div class="first-word-small">
            <div class="first-word-small-item">
              {{textOne}}
            </div>
            <div class="first-word-small-item">
              {{textTwo}}
            </div>
            <div class="first-word-small-item">
              {{textThree}}
            </div>
            <div v-if="$route.params.type === 1" class="first-word-small-item">
              {{textFour}}
            </div>
            <div v-else class="first-word-small-text">
              {{textFour}}
            </div>
            <div class="hide-img-box">
              <img class="first-img" :src=img alt="">
            </div>
          </div>
        </div>
        <consult-dialog class="consult-bottom" ref="consult" ></consult-dialog>
      </div>
    </div>
    <div class="home-con-box2">
      <div class="home-con" style="padding-top: 80px;">
        <div class="title">产品体验</div>
        <div class="home-con-item">
          <div
            class="card-box"
            v-for="(item, index) in dataSouce"
            :key="'card' + index"
          >
            <div class="header">
              <img class="icon" :src="item.img" :alt="item.title">
            </div>
            <div class="text">
              <span>{{ item.text }}</span>
            </div>
            <ul class="body">
              <li v-for="(text, i) in item.list" :key="'list' + i">
                {{ text }}
              </li>
            </ul>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import swTracker from '@/mixins/tracker.js'
import ConsultDialog from '@/components/ConsultDialog.vue'

export default {
  name: "ExperienceView",
  components: { ConsultDialog },
  mixins: [swTracker],
  data () {
    return {
      title: '预约交流与产品演示',
      minTitle: '留下您的联系方式，将免费获得',
      textOne: '上门产品演示或线上演示',
      textTwo: '专业顾问1对1全程服务',
      textThree: '企业定制化解决方案',
      textFour: '最新行业报告/营销资料包',
      img: 'https://cdn.ideamake.cn/sw-pc/images/reservation-icon.png',
      dataSouce: [
        {
          text: '思为营销云',
          list: [
            "赋能数字营销，更赋能数字管理",
            "增长获客 | 客户运营 | 客户转化",
          ],
          img: 'https://cdn.ideamake.cn/sw-pc/images/recommend-one.png'
        },
        {
          text: '微沙盘',
          list: [
            "可视化场景营销利器",
            "多端便捷看房 | 项目极致演绎 | 扫码分享传播",
          ],
          img: 'https://cdn.ideamake.cn/sw-pc/images/recommend-two.png'
        },
      ],
    }
  },
  watch: {
    '$route.params.type': {
      handler(val) {
        if(val === 1) {
          this.title = '预约交流与产品演示'
          this.minTitle = '留下您的联系方式，将免费获得'
          this.textOne = '上门产品演示或线上演示'
          this.textTwo = '专业顾问1对1全程服务'
          this.textThree = '企业定制化解决方案'
          this.textFour = '最新行业报告/营销资料包'
          this.img = 'https://cdn.ideamake.cn/sw-pc/images/reservation-icon.png'
        } else if(val === 2) {
          this.title = '免费获取最新营销资料'
          this.minTitle = '在《国央企数字化营销白皮书》中您将看到'
          this.textOne = '行业整体数字化趋势分析'
          this.textTwo = '6家头部国央企成功案例'
          this.textThree = '3位业内大咖前沿洞见'
          this.textFour = ' 填写右侧表单，免费获取完整版白皮书！'
          this.img = 'https://cdn.ideamake.cn/sw-pc/images/reservation-icon-two.png'
        } else if(val === 3) {
          this.title = '免费获取最新营销资料'
          this.minTitle = '在《新时代全民营销策略白皮书》中您将看到'
          this.textOne = '全民营销现状及前景分析'
          this.textTwo = '全民营销落地玩法策略'
          this.textThree = '10+地产全民营销实践案例'
          this.textFour = '填写右侧表单，免费获取完整版白皮书！'
          this.img = 'https://cdn.ideamake.cn/sw-pc/images/reservation-icon-three.png'
        }
     },
     immediate: true,
    },
  },
  // mounted () {
  //   console.log(this.$route.params.type)
  // },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
 .experience{
    background-color: #FFFFFF;
    .home-con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 122px;
        height: auto;
        .home-con-item{
          display: flex; 
          justify-content: space-between;
          .consult-top{
            display: none;
          }
          .consult-bottom{
            display: block;
          }
          .first-word {
            padding: 70px 0 0 15px;
            .first-word-big{
              font-size: 16px;
              font-weight: 500;
              color: #3777F6;
              margin-bottom: 30px;
              padding-left: 20px;
            }
            .first-word-small{
              margin-bottom: 80px;
              font-size: 14px;
              font-weight: 400;
              color: #5A5C60;
              .first-word-small-item{
                  margin-bottom: 5px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #5A5C60;
                  padding-left: 20px;
                  line-height: 28px;
                  &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    border: 2px solid #3777F6;
                    margin-right: 5px;
                  }
              }
              .first-word-small-text {
                  margin-bottom: 5px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #5A5C60;
                  padding-left: 20px;
                  line-height: 28px;
              }
            }
          }
          .first-img{
              width: 543px;
              height: 401px;
          }
      }
      .title {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        color: #2D2E30;
      }
    }
  }
  .home-con-box2{
    background: url(https://cdn.ideamake.cn/sw-pc/images/reservation-banner.png);
    background-size: cover;
    width: 100%;
    height: 574px;
    .home-con{
      margin: 0 auto;
      width: 800px;
      .title{
        margin-bottom: 55px;
      }
      .card-box {
        width: 280px;
        .header{
          text-align: center;
          .icon{
            width: 210px;
            height: 210px;
          }
        }
        .text{
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #2D2E30;
          line-height: 27px;
          padding-top: 20px;
          padding-bottom: 5px;
        }
        .body{
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #5A5C60;
          line-height: 26px;
        }
      }
    }
  }

@media screen and (max-width: 980px) {
  .experience .home-con.min-phone{
    padding: 1rem 0.16rem 0;
    .title{
      font-size: 0.24rem;
    }
    .home-con-item{
      padding-top: 0.2rem;
      .consult-top{
        display: block;
      }
      .consult-bottom{
        display: none;
      }
      .first-word {
        margin-top: 0.3rem;
        .first-word-big{
          font-size: 0.16rem;
          margin-bottom: 0.10rem;
        }
        .first-word-small-item{
          font-size: 0.13rem;
        }
      }
      .hide-img-box{
        width: 2.92rem;
        height: 2.41rem;
        margin: auto;
        margin-right: 0.20rem;
        .first-img{
          width: 2.92rem;
          height: 2.41rem;
        }
      }
    }
  }
  .home-con-box2{
    margin-top: 0.32rem;
    height: 100%;
    .home-con{
      .title{
        padding-top: 0.32rem;
        margin-bottom: 0.10rem;
      }
      .home-con-item{
        .card-box{
          margin: auto;
          .body{
            margin-bottom: 0.23rem;
          }
        }
      }
    }
  }
}
// 移动端适配
@import "@/assets/scss/min-index";
</style>
