const debounce = function (method, delay) {
  let timer = null
  return function () {
    let self = this
    let args = arguments
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      method.apply(self, args)
    }, delay)
  }
}

const throttle = function (method, delay) {
  let timer
  return function () {
    let self = this
    let args = arguments
    if (!timer) {
      timer = setTimeout(() => {
        timer = null
        method.apply(self, args)
      }, delay)
    }
  }
}

// 删除url参数
function delParam (url, ref) {
  var str = ''
  if (url.indexOf('?') !== -1) { str = url.substr(url.indexOf('?') + 1) } else { return url }
  var arr = ''
  var returnurl = ''
  if (str.indexOf('&') !== -1) {
    arr = str.split('&')
    for (let i in arr) {
      if (arr[i].split('=')[0] !== ref) {
        returnurl = returnurl + arr[i].split('=')[0] + '=' + arr[i].split('=')[1] + '&'
      }
    }
    return url.substr(0, url.indexOf('?')) + '?' + returnurl.substr(0, returnurl.length - 1)
  } else {
    arr = str.split('=')
    if (arr[0] === ref) { return url.substr(0, url.indexOf('?')) } else { return url }
  }
}

/**
 * 获取URL参数
 * @param {String} url
 * @return {Object} { url, params } url和参数
 */
function getUrlParams (url) {
  let urlArr = url.split('?')
  let params = {}
  let paramsStr = urlArr[1] ? urlArr[1] : ''
  let paramsArray = paramsStr.split('&')
  paramsArray.forEach(paramsItem => {
    let parArr = paramsItem.split('=')
    params[parArr[0]] = decodeURIComponent(parArr[1] || '')
  })
  return {
    url: urlArr[0],
    params
  }
}
/**
 * lodash.get
 * @param obj
 * @param path
 *  'a[0].b.c'
 *  'a.b.c'
 *  'a["b"][0].c'
 */
const qs = {
  /**
   * @description: 对象转化为查询字符
   * @param {Object} jsonData
   * @return:
   */
  stringify (jsonData) {
    return Object.keys(jsonData)
      .map(x => `${encodeURIComponent(x)}=${encodeURIComponent(jsonData[x])}`)
      .join('&')
  }
}

/**
 * 调整route.path路径
 */
function changeRoutePath (url) {
  const entString = url.charAt(url.length-1)
  if (url === '/') {
    return '/'
  } else if (entString === '/') {
    return url.slice(0, url.length-1)
  } else {
    return url
  }
}

export default {
  getUrlParams,
  debounce,
  delParam,
  throttle,
  changeRoutePath,
  qs
}
