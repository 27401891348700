<template>
  <div class="home">
    <div class="container">
      <el-carousel
        :interval="5000"
        arrow="never"
        height="600px"
        @change="bannerChange"
      >
        <el-carousel-item
          v-for="item in data"
          :key="item.developer"
        >
          <div class="item" :style="[disStyle]">
            <el-image
              class="image"
              style="width: 100vw; height: 600px"
              :src="item.cases"
              :alt="item.title"
            >
            </el-image>
            <div class="left">
              <div class="carousel">
                <div class="title">{{ item.title }}</div>
                <div class="text">{{ item.developer }}</div>
                <div class="first-word-big-button" @click="goReservation(item.type)" data-agl-cvt="5">{{ item.text }}</div>
                <div 
                  v-if="item.count" 
                  class="first-word-big-button button-two"
                  @click="goPage(item.https,item.goCases)"
                >
                  {{ item.count }}
                </div>
              </div>
            </div>
          </div>
          
        </el-carousel-item>
      </el-carousel>
      <div class="carousel-content">
        <div class="carousel-card">
          <div class="carousel-solution">
            <solution-card :dataSouce="card" />
          </div>
        </div>
      </div>
    </div>
    <div class="container min-phone">
      <el-carousel
        :interval="5000"
        arrow="never"
        class="min-carousel"
       
      >
        <el-carousel-item
          class="item"
          v-for="item in data"
          :key="item.developer"
        >
          <el-image
            class="image"
            :src="item.minCases"
            :alt="item.title"
          ></el-image>
          <div class="left">
            <div class="carousel">
              <div class="title">{{ item.title }}</div>
              <div class="text">{{ item.developer }}</div>
              <div class="first-word-big-button" @click="goReservation(item.type)" data-agl-cvt="5">{{ item.text }}</div>
              <div v-if="item.count" class="first-word-big-button button-two" @click="goPage(item.https,item.goCases)">{{ item.count }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="carousel-content">
        <div class="carousel-card">
          <div class="carousel-solution">
            <solution-card :dataSouce="card" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-con-box home-con-box1">
      <div class="cultural-wall">
        <new-menu></new-menu>
      </div>
      <menu-conent></menu-conent>
    </div>
    <!-- 客户公司 -->
    <customer-recognition></customer-recognition>
    <!-- 数字化营销领导者 -->
    <digital-marketing></digital-marketing>
    <!-- 升级 -->
    <marketing-upgrade></marketing-upgrade>
  </div>
</template>

<script>
import customerRecognition from '@/components/CustomerRecognition.vue'
import digitalMarketing from '@/components/DigitalMarketing.vue'
import marketingUpgrade from '@/components/MarketingUpgrade.vue'
import newMenu from './components/newMenu.vue'
import menuConent from './components/menuConent.vue'
import swTracker from '@/mixins/tracker.js'
import SolutionCard from "./components/Card.vue";

export default {
  name: "HomeView",
  mixins: [swTracker],
  components: {
    customerRecognition,
    digitalMarketing,
    marketingUpgrade,
    newMenu,
    menuConent,
    SolutionCard,
  },
  data () {
    return {
      onVideoSrc: '',
      carousel: "https://cdn.ideamake.cn/sw-pc/images/all-round-solution.png",
      data: [
        {
          type: 1,
          developer: "思为营销云，专注中国房地产数字化营销12年，已成为先进房企的共同选择",
          title: "赋能数字营销，更赋能数字管理",
          text: "立即体验",
          count: "",
          cases: "https://cdn.ideamake.cn/sw-pc/images/home-banner-one.png",
          minCases: "https://cdn.ideamake.cn/sw-pc/images/min-home-banner-one.png",
        },
        {
          type: 2,
          developer: "全面洞见数字化营销趋势与最新案例",
          title: "《国央企数字化营销白皮书》重磅出炉",
          text: "立即获取",
          count: "更多干货",
          goCases: false,
          cases: "https://cdn.ideamake.cn/sw-pc/images/home-banner-two.png",
          minCases: "https://cdn.ideamake.cn/sw-pc/images/min-home-banner-two.png",
          https: 'https://applmkip5ay1116.pc.xiaoe-tech.com/page/2088805?navIndex=0',
        },
        {
          type: 3,
          developer: "立即掌握快速拓客的全民营销新玩法",
          title: "《新时代全民营销策略白皮书》首发",
          text: "获取方案",
          count: "更多案例",
          goCases: true,
          cases: "https://cdn.ideamake.cn/sw-pc/images/home-banner-three.png",
          minCases: "https://cdn.ideamake.cn/sw-pc/images/min-home-banner-three.png",
          https: 'cases',
        },
      ],
      card: [
        {
          title: "行业报告",
          list: [
            "国央企数字化营销白皮书",
          ],
          text: '立即下载',
          code: false,
          type: 2,
          img: 'https://cdn.ideamake.cn/sw-pc/images/card-one.png',
          banner: 'https://cdn.ideamake.cn/sw-pc/images/card-background-one.png'
        },
        {
          title: "前沿打法",
          list: [
            "新时代全民营销策略白皮书",
          ],
          text: '立即获取',
          code: false,
          type: 3,
          img: 'https://cdn.ideamake.cn/sw-pc/images/card-two.png',
          banner: 'https://cdn.ideamake.cn/sw-pc/images/card-background-two.png'
        },
        {
          title: "优质案例",
          list: [
            "抖音直播如何实现客户留资率10倍提升？",
          ],
          text: '立即了解',
          code: true,
          type: 4,
          img: 'https://cdn.ideamake.cn/sw-pc/images/card-three.png',
          banner: 'https://cdn.ideamake.cn/sw-pc/images/card-background-three.png'
        },
      ],
      discountStyle: 0
    }
  },
  computed: {
    disStyle () {
      let style = '#cce3ff'
      if(this.discountStyle === 0) {
        style = '#cce3ff'
      }else if(this.discountStyle === 1) {
        style = '#d6e4fe'
      }else if(this.discountStyle === 2) {
        style = '#c6d9ff'
      }
      return  {
        background:`${style}`
      }
    },
  },
  watch: {
    'carousel' (val) {
      let box2 = this.$refs.box2
      box2.style.backgroundImage = 'url('+val+')';
      box2.style.backgroundSize = 'cover'
    },
  },
  methods: {
    bannerChange(e) {
      this.discountStyle = e
    },
    carouselData (data) {
      this.carousel = data
      let box2 = this.$refs.box2
      box2.style.background = 'url('+data+')';
    },
    // 跳转立即体验
    goExperience() {
      this.$router.push({ path: '/experience' })
    },
    // 跳转预约演示
    goReservation(type) {
      this.$router.push({ name: 'reservation',params:{type} })
      // this.$router.push({ path: '/reservation' })
      // 调用百度统计的代码
      switch (type) {
        case 1:
          window._hmt.push(['_trackEvent', 'button','click', '首页轮播第一张立即体验按钮']);
          break;
        case 2:
          window._hmt.push(['_trackEvent', 'button','click', '首页轮播第二张立即获取按钮']);
          break;
        case 3:
          window._hmt.push(['_trackEvent', 'button','click', '首页轮播第三张获取方案按钮']);
          break;
      }
    },
    goPage(https,goCases) {
      if(goCases) {
        window._hmt.push(['_trackEvent', 'button','click', '首页第二张轮播更多案例按钮']);
        this.$router.push({ path: https })
      }else {
        window._hmt.push(['_trackEvent', 'button','click', '首页第二张轮播更多干货按钮']);
        window.open(https, '_blank')
      }
    }, 
 },
}
</script>
<style lang="scss" scoped>
@import "./home";
.fixed{
  position: fixed;
  top: 0;
  left: 0;
}
// 移动端布局
@import "@/views/home/min-home";
@media screen and (max-width: 980px) {
  .home{
    .container{
      display: none;
      &.min-phone{
        display: block;
        .item {
          margin-top: 0.62rem;
          height: 4.66rem;
          background-size: 390px 466px;
          text-align: center;
          .image {
            width: 100%;
            height: 100%;
          }
          .left{
            .carousel {
              width: 100%;
              top: 10%;
            }
            .title {
              font-size: 0.20rem;
              font-weight: 500;
              color: #2D2E30;
              line-height: 0.28rem;
            }
            .text {
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2D2E30;
              line-height: 0.24rem;
              margin-bottom: 0.24rem;
              margin-top: 0.05rem;
            }
          }
        }
        .carousel-card {
          width: 100%;
          .card{
            justify-content: space-around;
            flex-wrap: wrap;
            height: 5.40rem;
          }
          .carousel-solution {
            margin-top: -0.28rem;
          }
        }
        ::v-deep .el-carousel__indicators--horizontal {
            bottom: 0.55rem;
            width: 3.90rem;
            text-align: center;
            margin-left: 0rem;
            .el-carousel__button {
              width: 0.20rem;
            }
          }
      }
      .min-carousel{
        height: 5.793rem;  
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .home{
    .container{
      .item{
        text-align: center;
        overflow: hidden;
        .left{
          .carousel{
            text-align: left;
          }
        }
        .image {
          width: 1920px !important;
        }
      }
      
    }
  }
}
</style>
