<template>
  <div class="more-page">
    <h3 class="title">了解更多</h3>
    <div class="container">
      <div class="item">
          <img src="https://cdn.ideamake.cn/sw-pc/images/more-03.png" alt="更多实时动态">
          <p>更多实时动态</p>
      </div>
      <div class="item">
          <img src="https://cdn.ideamake.cn/sw-pc/images/more-01.png" alt="更丰富营销案例库">
          <p>更丰富营销案例库</p>
      </div>
      <div class="item">
          <img src="https://cdn.ideamake.cn/sw-pc/images/more-02.png" alt="更真实企业文化">
          <p>更真实企业文化</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutMore",
};
</script>

<style lang="scss" scoped>
.more-page {
  width: 100%;
  min-height: 380px;

  background: #f7f9fb;
  padding-top: 80px;
  box-sizing: border-box;
  text-align: center;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-around;
    .item {
     line-height: 40px;;
     font-size: 14px;
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>