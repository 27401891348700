<template>
  <div
    class="digital-content"
    :style="
      isSolutionPage ? solutionPageFooterStyle : notSolutionPageFooterStyle
    "
  >
    <div class="digital-marketing" v-if="!isSolutionPage">
      <div class="marketing-title">数字化营销领导者</div>
      <div class="marketing-box">
        <div class="marketing-box-item" v-for="item in datas" :key="item.title">
          <div class="marketing-flex">
            <div class="marketing-big">
              <span class="marketing-number">{{ item.value }}</span
              >{{ item.unit }}
            </div>
            <div class="marketing-small">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="digital-marketing" v-else>
      <div class="digital-marketing-wrapper">
        <div class="new-marketing-title">{{ title }}</div>
        <div class="marketing-subTitle">{{ subTitle }}</div>
        <div class="digital-marketing-body">
          <div
            class="digital-marketing-item"
            v-for="item in datas"
            :key="item.title"
          >
            <div class="digital-marketing-item-top">
              <span class="title">{{ item.value }}</span>
              <span class="unit">{{ item.unit }}</span>
            </div>
            <div class="digital-marketing-item-bottom">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="digital-marketing-action">
          <div class="btn blue" @click="goReservation">立即体验</div>
          <div class="btn white" @click="onNext()">下一个方案</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bannerList, dataList } from '@/views/solution/data.js';
import utils from '@/utils';

export default {
  name: 'digital-marketing',
  props: {},
  data() {
    return {
      activeName: 'first',
      //   dataList:[],
      isSolutionPage: false, //是否是首页
      title: '', // 移动售楼处
      subTitle: '', // 快速构建线上营销阵地，打造流畅的买房卖房体验
      datas: [
        {
          title: '千亿房企覆盖率',
          value: '95',
          unit: '%',
        },
        {
          title: '复购率',
          value: '80',
          unit: '%',
        },
        {
          title: '服务客户',
          value: '600',
          unit: '+',
        },
        {
          title: '覆盖案场数',
          value: '6,400',
          unit: '',
        },
      ],
      moduleName: '',
      nextPathName: '',

      solutionPageFooterStyle: {
        background: `url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-footer-solution.png')`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      notSolutionPageFooterStyle: {
        background: `url('https://cdn.ideamake.cn/sw-pc/images/web3.0/v2/big-home-footer.png')`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    goReservation() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `${this.moduleName}底部立即体验按钮`,
      ]);
      this.$router.push({ name: 'reservation', params: { type: 1 } });
    },
    onNext() {
      window._hmt.push([
        '_trackEvent',
        'button',
        'click',
        `${this.moduleName}底部下一个方案按钮`,
      ]);
      this.$router.push({ name: this.nextPathName });
    },
  },

  watch: {
    '$route.path': {
      handler() {
        const pathUrl = utils.changeRoutePath(this.$route.path);
        // 如果不是解决方案中的路由，需要展示 “数字化营销领导者”
        this.isSolutionPage = pathUrl.includes('/solution');

        if (this.isSolutionPage) {
          const pageBannerInfo = bannerList.find(
            (item) => item.pathUrl === pathUrl
          );
          this.title = pageBannerInfo.footerTitle
            ? pageBannerInfo.footerTitle
            : pageBannerInfo.banner.length > 0
            ? pageBannerInfo.banner[0].title
            : '';
          const pageIndex = dataList.findIndex(
            (item) => item.pathUrl === pathUrl
          );
          if (pageIndex >= 0) {
            const pageInfo = dataList[pageIndex];
            this.subTitle = pageInfo.footerSubTitle || pageInfo.title;
            this.moduleName = pageInfo.pathName;
            const nextIndex =
              pageIndex === dataList.length - 1 ? 0 : pageIndex + 1;
            this.nextPathName = dataList[nextIndex].pathName;
          }
        }
      },
      immediate: true,
    },
  },

  created() {
    // this.dataList = dataList
  },
};
</script>
<style lang="scss" scoped>
.digital-content {
  background: #f7f9fb;
  .digital-marketing {
    margin: 0 auto;
    width: 1200px;
    padding: 70px 0 80px;
    // height: 718px;
    .marketing-title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2d2e30;
      text-align: center;
      margin-bottom: 50px;
    }
    .marketing-box {
      display: flex;
      height: 152px;
      background: linear-gradient(180deg, #f5f8ff 0%, #ffffff 100%);
      box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25),
        inset 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
      border: 2px solid #ffffff;
      .marketing-box-item {
        width: 300px;
        height: 152px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .marketing-flex {
          .marketing-big {
            color: #3777f6;
            font-size: 14px;
            .marketing-number {
              font-size: 32px;
              font-weight: 600;
            }
          }
          .marketing-small {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5a5c60;
            margin-top: 12px;
          }
        }
      }
    }
    .new-marketing-title {
      height: 40px;
      font-size: 32px;
      font-weight: 500;
      color: #2d2e30;
      line-height: 40px;
    }
    .marketing-subTitle {
      height: 26px;
      font-size: 18px;
      font-weight: 400;
      color: #5a5c60;
      line-height: 26px;
      margin-top: 16px;
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &-body {
      margin-top: 32px;
      display: flex;
    }
    &-item {
      width: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #b6bfd0;
      &:last-child {
        border: none;
      }
      &-top {
        color: #3777f6;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #3777f6;
        }
        .unit {
          font-size: 14px;
          font-weight: 500;
        }
      }
      &-bottom {
        //   margin-top: 12px;
        font-size: 14px;
        color: #5a5c60;
      }
    }
    &-action {
      display: flex;
    }
    .btn {
      width: 132px;
      height: 48px;
      border-radius: 2px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 48px;
      cursor: pointer;
      text-align: center;
      margin-top: 32px;
    }
    .blue {
      background: #3777f6;
      color: #ffffff;
      margin-right: 10px;

      &:hover {
        background: #5f92f8;
      }
    }
    .white {
      color: #3777f6;
      background: #ffffff;
      &:hover {
        background: #eef5fe;
      }
    }
  }
}

// 移动端布局
@import '@/views/home/min-home';
</style>
