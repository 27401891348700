<template>
  <div class="about">
    <div class="banner">
      <div class="banner-text">
        <span class="color">思</span>行合一 顺势而<span class="color">为</span>
      </div>
    </div>
    <!-- 侧边导航 -->
    <div class="left-nav" v-show="leftNavShow">
      <el-tabs tab-position="left" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司简介" name="公司简介"></el-tab-pane>
        <el-tab-pane label="企业文化" name="企业文化"></el-tab-pane>
        <el-tab-pane label="发展历程" name="发展历程"></el-tab-pane>
        <el-tab-pane label="公司荣誉" name="公司荣誉"></el-tab-pane>
        <el-tab-pane label="联系我们" name="联系我们"></el-tab-pane>
        <el-tab-pane label="了解更多" name="了解更多"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 公司简介 -->
    <div class="panel scroll-item">
      <div class="panel-content">
        <h2 class="title">公司简介</h2>
        <div class="intro">
          <div class="intro-left">
            <h3 class="ititle">Since2011</h3>
            <h4 class="subtitle">深圳思为科技有限公司</h4>
            <p class="text">
              思为科技成立于2011年，是一家致力于用领先技术驱动不动产营销数字化升级的SaaS服务商，聚焦营销获客-连接-洞察-运营-转化全链路，从消费端重塑业务流程，推动行业从以“货”为中心的“信息化”，向以“人”为中心的“数字化”迈进。围绕用户增长，用户洞察，和用户运营，拉通策销渠一体，打造整套的客户全生命周期管理系统。
            </p>
            <p class="text">
              目前，思为科技产品在百强国央企开发商覆盖率超90%，在千亿级开发商覆盖率超95%，并与超过半数的TOP20开发商达成集团、区域营销数字化的深度合作。
            </p>
          </div>
          <div class="intro-right">
            <img :src="AboutIntro01" alt="深圳思为科技有限公司" />
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="panel scroll-item" style="background:#fff">
      <div class="panel-content">
        <h2 class="title">企业文化</h2>
          <about-culture/>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="panel course scroll-item">
      <div class="course_bag">
        <div class="panel-content">
          <h2 class="title">发展历程</h2>
            <about-history/>
        </div>
      </div>
    </div>
    <digital-marketing/>
    <!-- 公司荣誉 -->
    <about-honor class="scroll-item"/>
    <!-- 联系我们 -->
    <about-address class="scroll-item"/>
    <!-- 了解更多 -->
    <about-more class="scroll-item"/>
  </div>
</template>

<script>
import digitalMarketing from './components/DigitalMarketing.vue'
import AboutAddress from './components/Address.vue'
import AboutHonor from './components/Honor.vue'
import AboutMore from './components/More.vue'
import AboutCulture from './components/Culture.vue'
import AboutHistory from './components/History.vue'
import swTracker from '@/mixins/tracker.js'

export default {
  name: "AboutView",
  mixins: [swTracker],
  components:{
    digitalMarketing,
    AboutAddress,
    AboutHonor,
    AboutMore,
    AboutCulture,
    AboutHistory
  },
  data() {
    return {
      leftNavShow: false,
      activeName: '公司简介',
      AboutIntro01: 'https://cdn.ideamake.cn/sw-pc/images/about-intro-01.png',
      AboutBanner01: 'https://cdn.ideamake.cn/sw-pc/images/banner/banner-about-01.png',
      AboutBg01: 'https://cdn.ideamake.cn/sw-pc/images/about-bg-01.png',
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const lis = document.querySelectorAll(".scroll-item")
      this.leftNavShow = window.pageYOffset > 200 && window.pageYOffset < lis[5].offsetTop -50 ? true : false
      if(window.pageYOffset < lis[0].offsetTop){
        this.activeName='公司简介'
      }else if(window.pageYOffset < lis[1].offsetTop){
        this.activeName='企业文化'
      }else if(window.pageYOffset < lis[2].offsetTop){
        this.activeName='发展历程'
      }else if(window.pageYOffset < lis[3].offsetTop){
        this.activeName='公司荣誉'
      }else if(window.pageYOffset < lis[4].offsetTop){
        this.activeName='联系我们'
      }else if(window.pageYOffset < lis[5].offsetTop){
        this.activeName='了解更多'
      }
    },
    handleClick(tab){
      const index = Number(tab.index)
      switch (index) {
        case 0:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航公司简介']);
          break;
        case 1:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航企业文化']);
          break;
        case 2:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航发展历程']);
          break;
        case 3:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航公司荣誉']);
          break;
        case 4:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航联系我们']);
          break;
        case 5:
          window._hmt.push(['_trackEvent', 'button','click', '关于我们关于思为左侧导航了解更多']);
          break;
      }
      const lis = document.querySelectorAll(".scroll-item")
      this.scrollAnimate(lis[index].offsetTop - 80)
    },
    scrollAnimate (scrollHeight){
      window.scrollTo({
        top: scrollHeight,
        behavior: "smooth"
      })
    }
  },
};
</script>
<style lang="scss" scoped>
// 响应式媒体查询
@media screen and (max-width: 1500px) {
  .about {
    .left-nav {
      display: none;
    }
  }
}
.about {
  width: 100%;
  height: 100%;
  .left-nav {
    position: fixed;
    width: 100px;
    height: 500px;
    left: calc(50% - 740px);
    top: 280px;
    z-index: 100;
  }
  .banner {
    position: relative;
    width: 100%;
    height: 480px;
    background: url('https://cdn.ideamake.cn/sw-pc/images/banner/banner-about-01.png');
    background-size: 100% 480px;
    background-position: 50%;
    background-repeat: no-repeat;
    &-text {
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      bottom: 0;
      width: 380px;

      margin: auto;
      font-size: 42px;
      font-weight: 600;
      color: #fff;
    }
    .color {
      color: #3777f6;
    }
  }
  .panel {
    min-height: 630px;
    background: #F7F9FB;
    &.course{
      height:600px;
     
      position: relative;
      &::before{
        content: ' ';
        width: 50%;
        height:100%;
        background: #F5F6FA;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
      &::after{
        content: ' ';
        width: 50%;
        height:100%;
        background: #EDEFF4;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
      .course_bag{
        position: absolute;
        width: 100%;
        height:628px;
        z-index: 10;
        background-image: url("https://cdn.ideamake.cn/sw-pc/images/about-bg-01.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        
      }
    }
    &-content {
      width: 1200px;
      margin: 0 auto;
      
      .title {
        padding-top: 80px;
       
        font-size: 32px;
        font-weight: 500;
        color: #2d2e30;
        text-align: center;
      }
      .intro {
        display: flex;
        margin-top: 60px;

        color: #2d2e30;
        &-left {
          width: 50%;
          padding-right: 40px;
          .ititle {
            font-size: 32px;
            font-weight: 600;
            text-align: left;
          }
          .subtitle {
            font-size: 24px;
            font-weight: 500;
            margin: 16px 0 26px 0;
          }
          .text {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 16px;
          }
        }
        &-right {
          width: 50%;
        }
      }
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>
