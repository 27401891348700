<template>
  <div class="min-phone-nav-box">
    <div class="min-phone-nav">
        <div class="nav-left">
            <div class="icon-item" @click="onConsult" data-agl-cvt="5">
                
                <el-popover
                  placement="bottom"
                  popper-class="wxQRCode-popper wxQRCode-bottom"
                  trigger="click"
                >
                  <div class="popover-box">
                    <img class="wxQRCode-img" src="https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code-sider.jpeg">
                    <div class="title">添加微信</div>
                    <div class="text">专属客服将为您介绍更多产品资讯</div>
                  </div>
                 <div slot="reference">
                  <!-- <div class="img-box">
                    <img class="icon-item-img" src="https://cdn.ideamake.cn/sw-pc/images/consult_icon_phone.png">
                  </div>
                  <div style="text-align: center;">咨询</div> -->
                 </div>
                  <!-- <div>咨询</div> -->
                  <!-- <div class="big-button button-detail" slot="reference">咨询</div> -->
                </el-popover>
            </div>
            <!-- <div class="icon-item" @click="goPartner">
                <div class="img-box">
                    <img class="icon-item-img" src="https://cdn.ideamake.cn/sw-pc/images/follow_icon_phone.png">
                </div>
                <p>合作伙伴</p>
            </div> -->
        </div>
        <div class="nav-right" @click="goExperience">
            立即体验
        </div>
    </div>
  </div>
</template>
<script>
import { swTrackerClickReport } from '@/tracker.js'
export default {
  name: 'MinPhoneNav',
  data () {
    return {
      pathName: '思为科技首页',
    }
  },
  watch: {
    '$route.path' () {
      this.pathName = this.$route.name
    },
  },
  created () {
    this.pathName = this.$route.name
  },
  methods: {
    onConsult (){
      window._hmt.push(['_trackEvent', 'button','click', '移动端底部咨询按钮'])
      swTrackerClickReport('sw_pc_free_trial', '移动端底部导航-咨询', this.pathName)
      this.$emit("onConsult");
    },
    // 跳转立即体验
    goExperience() {
      window._hmt.push(['_trackEvent', 'button','click', '移动端底部立即体验按钮']);
      this.$router.push({ path: '/reservation' })
    },
    // 跳转合作伙伴
    goPartner() {
      this.$router.push({ path: '/partner' })
    },
  }
}
</script>
<style lang="scss" scoped>
.min-phone-nav-box{
    display: none;
   
}
  
@import "@/assets/scss/min-index";
</style>
