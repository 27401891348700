<template>
  <div class="cases-con">
    <div class="banner">
      <div class="banner-text">
        <span class="color">兑现数字价值 帮助客户成功</span>
      </div>
    </div>
    <cases-ka />
    <Cases-item :showNumber="showNumber"/>
    <customer-recognition></customer-recognition>
    <digital-marketing></digital-marketing>
    <marketing-upgrade></marketing-upgrade>
  </div>
</template>

<script>
import CasesKa from "./components/CasesKa.vue"
import CasesItem from "@/components/CasesItem.vue"
import customerRecognition from "@/components/CustomerRecognition.vue"
import digitalMarketing from "@/components/DigitalMarketing.vue"
import marketingUpgrade from "@/components/MarketingUpgrade.vue"
import swTracker from '@/mixins/tracker.js'

export default {
  name: "CasesView",
  mixins: [swTracker],
  components: {
    CasesKa,
    CasesItem,
    customerRecognition,
    digitalMarketing,
    marketingUpgrade,
  },
  data() {
    return {
      browserWigth: 0,
    };
  },
  computed: {
    showNumber () {
      return this.browserWigth > 980 ? 6 : 4
    },
  },
  created(){
    window.addEventListener('resize', this.getBrowserWidth)
    this.getBrowserWidth()
  },
  destroyed(){
    window.removeEventListener('resize', this.getBrowserWidth)
  },
  methods: {
    // 获取浏览器宽度
    getBrowserWidth(){
      this.browserWigth = window.innerWidth
    }
  },
};
</script>
<style lang="scss" scoped>
.cases-con {
  width: 100%;
  height: 100%;
  .banner {
    position: relative;
    height: 480px;
    width: 100%;
    background: url('https://cdn.ideamake.cn/sw-pc/images/banner/banner-case-01.png');
    background-size: 100% 480px;
    background-position: 50%;
    background-repeat: no-repeat;
    &-text {
      position: absolute;
      left: 0;
      top: 47%;
      right: 0;
      bottom: 0;
      width: 530px;

      margin: auto;
      font-size: 42px;
      font-weight: 600;
      color: #fff;
    }
  }
}

// 移动端布局
@import "@/views/cases/min-cases";
</style>
