<template>
  <div class="history-page">
    <div class="step-box">
      <div class="step">
        <div
          class="step-item"
          @click="onClick(index)"
          v-for="(item, index) in historyData"
          :key="item.year"
          :class="{ active: index === active }"
        >
          <span class="step-item-dot"></span>
          <div class="step-item-year">{{ item.year }}</div>
        </div>
      </div>
    </div>
    

    <div class="intro">
      <div
        class="intro-year"
        v-for="y in historyData[active]['list']"
        :key="y.year"
      >
        <h4>{{ y.year }}</h4>
        <div class="intro-year-box">
          <div class="item" v-for="item in y.data" :key="item.title">
            <h5>{{ item.title }}</h5>
            <p v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutHistory",
  computed: {
    yearData() {
      const year = this.historyData[this.active]["year"];

      let data = [
        {
          year: year,
          data: this.historyData[this.active]["list"],
        },
      ];
      const lenth = data[0].data.length;
      if (lenth === 1 && year !== 2011) {
        //   少于2个向上一取个
        const temp = this.historyData[this.active + 1]["list"];

        if (temp.length >= 2) {
          // 取上一年
          data.push({
            year: temp[0]["year"],
            data: temp.splice(0, 2),
          });
        } else {
          // 取上上一年
          const ppre = this.historyData[this.active + 2]["list"];
          data.push({
            year: ppre[0]["year"],
            data: ppre.splice(0, 1),
          });
        }
        console.log("temp", temp);
      }
      console.log("data1", data);
      return data;
    },
  },

  data() {
    return {
      active: 0,
      historyData: [
      {
          year: "2023",
          list: [
            {
              year: "2023",
              data: [
                {
                  year: "2023",
                  title: "02月",
                  text: "与行稳智能达成“行业独家战略合作”协议",
                },
                {
                  year: "2023",
                  title: "03月",
                  text: "入选2022年深圳市“专精特新”中小企业",
                },
                {
                  year: "2023",
                  title: "03月",
                  text: "获批深圳市博士后创新实践基地",
                },
              ],
            },
          
          ],
        },
        {
          year: "2022",
          list: [
            {
              year: "2022",
              data: [
                {
                  year: "2022",
                  title: "11月",
                  text: "荣获2022毕马威“中国领先地产科技企业50",
                },
                {
                  year: "2022",
                  title: "09月",
                  text: "与中科院深圳先进院共创元宇宙空间计算联合实验室",
                },
                {
                  year: "2022",
                  title: "03月",
                  text: "完成由招商局创投、火山石投资领投的C轮融资",
                },
              ],
            },
          
          ],
        },
        {
          year: "2021",
          list: [
            {
              year: "2021",
              data: [
                {
                  year: "2021",
                  title: "12月",
                  text: "与循环智能达成“行业独家战略合作”协议",
                },
                {
                  year: "2021",
                  title: "11月",
                  text: "荣获克而瑞“地产数字力TOP20科技领军企业",
                },
                {
                  year: "2021",
                  title: "08月",
                  text: "思为科技十周年，全面升级品牌使命、愿景、价值观",
                },
                {
                  year: "2021",
                  title: "04月",
                  text: "完成由火山石投资领投，同创伟业跟投的C1轮融资",
                },
                {
                  year: "2021",
                  title: "03月",
                  text: "全面实现地产营销“线上线下一体化”闭环",
                },
                {
                  year: "2021",
                  title: "01月",
                  text: "思为云创营销场景覆盖率达95%，服务用户破1亿",
                },
              ],
            },
          ],
        },
        {
          year: "2020",
          list: [
            {
              year: "2020",
              data: [
                {
                  year: "2020",
                  title: "12月",
                  text: "荣获2020深圳创新榜“深圳最具投资价值企业50强",
                },
                {
                  year: "2020",
                  title: "11月",
                  text: "与8家TOP20开发商建立区域性、集团性合作",
                },
                {
                  year: "2020",
                  title: "08月",
                  text: "思为营销云小程序上线超300个",
                },
                {
                  year: "2020",
                  title: "07月",
                  text: "与保利旗下锐云科技成立创新联合实验室",
                },
                {
                  year: "2020",
                  title: "06月",
                  text: "推出企业微信生态内首个地产数字化营销解决方案",
                },
                {
                  year: "2020",
                  title: "05月",
                  text: "获机器之心2020金炼奖“穿越严峻时期的最强AI企业",
                },
                {
                  year: "2020",
                  title: "02月",
                  text: "思为营销云小程序上线超100个，在新冠疫情期间实现规模化行业赋能",
                },
                
              ],
            },
          ],
        },
        {
          year: "2019",
          list: [
            {
              year: "2019",
              data: [
              {
                  year: "2019",
                  title: "11月",
                  text: "荣获2019深圳高科技高成长20强企业",
                },
                {
                  year: "2019",
                  title: "10月",
                  text: "联合碧桂园共建“凤凰云创新营销联合实验室",
                },
                {
                  year: "2019",
                  title: "08月",
                  text: "入选首期腾讯SaaS加速器，获腾讯全生态扶持",
                },
                {
                  year: "2019",
                  title: "06月",
                  text: "完成B轮融资，由朗玛峰领投，同创伟业、微禾跟投，员工参与认股",
                },
              ],
            },
          ],
        },
        {
          year: "2018",
          list: [
            {
              year: "2018",
              data: [
                {
                  year: "2018",
                  title: "09月",
                  text: "拿下纽约、伦敦、澳洲、新加坡、马来西亚等多处地标项目，海外扩张步伐加快",
                },
                {
                  year: "2018",
                  title: "01月",
                  text: "提出“流态营销”理论，为房地产数字化营销2.0时代提供理论支撑",
                }
              ],
            },
            {
              year: "2017",
              data: [
              {
                  year: "2017",
                  title: "12月",
                  text: "完成由同创伟业领投的A+轮融资",
                },
                {
                  year: "2017",
                  title: "11月",
                  text: "荣获中经联盟“2017中国房地产营销杰出贡献奖",
                },
                {
                  year: "2017",
                  title: "10月",
                  text: "获得国家高新技术企业认证",
                },
              ],
            },
          ],
        },
        {
          year: "2017",
          list: [
            {
              year: "2017",
              data: [
              {
                  year: "2017",
                  title: "12月",
                  text: "完成由同创伟业领投的A+轮融资",
                },
                {
                  year: "2017",
                  title: "11月",
                  text: "荣获中经联盟“2017中国房地产营销杰出贡献奖",
                },
                {
                  year: "2017",
                  title: "10月",
                  text: "获得国家高新技术企业认证",
                },
              ],
            },
            {
              year: "2016",
              data: [
                {
                  year: "2016",
                  title: "12月",
                  text: "荣获中国地产金砖奖·创新技术大奖",
                },
                {
                  title: "10月",
                  year: "2016",
                  text: "完成由朗玛峰领投的A轮融资",
                },
              ],
            },
          ],
        },
        {
          year: "2016",
          list: [
            {
              year: "2016",
              data: [
                {
                  year: "2016",
                  title: "12月",
                  text: "荣获中国地产金砖奖·创新技术大奖",
                },
                {
                  title: "10月",
                  year: "2016",
                  text: "完成由朗玛峰领投的A轮融资",
                },
              ],
            },
            {
              year: "2015",
              data: [
                {
                  year: "2015",
                  title: "08月",
                  text: "完成天使轮融资，先后在深圳、海口、三亚、南宁等地设置分公司及分支机构",
                },
              ],
            },
          ],
        },
        {
          year: "2015",
          list: [
          {
              year: "2015",
              data: [
                {
                  year: "2015",
                  title: "08月",
                  text: "完成天使轮融资，先后在深圳、海口、三亚、南宁等地设置分公司及分支机构",
                },
              ],
            },
            {
              year: "2013",
              data: [
                {
                  year: "2013",
                  title: "07月",
                  text: "思为科技革命性多点触控平台亮相美国InfoComm展示，获得高度好评",
                },
              ],
            },
            {
              year: "2012",
              data: [
                {
                  year: "2012",
                  title: "05月",
                  text: "思为移动触控售楼软件获软件著作登记书，思为科技成为广东省认证的双软企业",
                },
              ]
            }
          ],
        },
        {
          year: "2013",
          list: [
            {
              year: "2013",
              data: [
                {
                  year: "2013",
                  title: "07月",
                  text: "思为科技革命性多点触控平台亮相美国InfoComm展示，获得高度好评",
                },
              ],
            },
            {
              year: "2012",
              data: [
                {
                  year: "2012",
                  title: "05月",
                  text: "思为移动触控售楼软件获软件著作登记书，思为科技成为广东省认证的双软企业",
                },
              ]
            },
            {
              year: "2011",
              data: [
                {
                  year: "2011",
                  title: "08月",
                  text: "思为科技成立，开始研发Idea-Make售楼系统",
                },
              ],
            },
          ],
        },
        {
          year: "2012",
          list: [
            {
              year: "2012",
              data: [
                {
                  year: "2012",
                  title: "05月",
                  text: "思为移动触控售楼软件获软件著作登记书，思为科技成为广东省认证的双软企业",
                },
              ]
            },
            {
              year: "2011",
              data: [
                {
                  year: "2011",
                  title: "08月",
                  text: "思为科技成立，开始研发Idea-Make售楼系统",
                },
              ],
            },
          ],
        },
        {
          year: "2011",
          list: [
            {
              year: "2011",
              data: [
                {
                  year: "2011",
                  title: "08月",
                  text: "思为科技成立，开始研发Idea-Make售楼系统",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    onClick(key) {
      this.active = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-page {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  .step {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 14px 14px 14px 30px;
    &-item {
      font-size: 20px;
      color: #878a90;
      cursor: pointer;
      z-index: 2;
      &-dot {
        display: block;
        width: 12px;
        height: 12px;
        background: #a6c4ff;
        border-radius: 50%;
      }
      &-year {
        width: 80px;
        text-align: center;
        margin-left: -30px;
      }
    }
  }
  .step::after {
    content: "";
    height: 1px;
    width: calc(100% - 84px);
    background: #5597fd;
    position: absolute;
    left: 30px;
    top: 20px;
    z-index: 1;
  }
  .active {
    font-size: 22px;
    font-weight: 500;
    color: #2d2e30;
    .step-item-dot {
      position: relative;
      width: 16px;
      height: 16px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(55, 119, 246, 0.7);
      border: 1px solid #3777f6;
    }
    .step-item-dot::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background: linear-gradient(180deg, #3777f6 0%, #5597fd 100%);
      border-radius: 50%;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .intro {
    margin-top: 64px;
   
    display: flex;

    &-year {
      min-width: 300px;
      margin-right: 50px;
      color: #2d2e30;
      h4 {
        font-size: 20px;
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #ddd;
      }
      &-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 80px;
        .item {
          width: 260px;
          font-size: 14px;
          font-weight: 400;
          margin-right: 30px;
          line-height: 26px;
          white-space: pre-line;
          margin-bottom: 24px;
          h5 {
            font-weight: 500;
            margin: 16px 0;
          }
        }
        .item:last-child {
          margin-right: 0;
        }
      }
    }
    &-year:last-child {
      margin-right: 0;
    }
  }
}

// 移动端布局
@import "@/views/about/min-about";
</style>