<template>
  <div class="right-nav">
    <div class="nav-list">
      <div class="nav-item active">
        <div class="nav-content" @click="goReservation">
          <div class="nav-item-li" @click="onConsult" data-agl-cvt="5">
            <div class="nav-item-content">
              <img class="nav-item-img" src="https://cdn.ideamake.cn/sw-pc/images/bottom-icon-one.png">
            </div>
              <img class="nav-item-img hover" src="https://cdn.ideamake.cn/sw-pc/images/bottom-active-one.png">
          </div>
          预约演示
        </div>
        <div class="nav-content">
          <el-popover
            placement="right"
            popper-class="wxQRCode-popper"
            trigger="hover">
            <div class="popover-box">
              <img class="wxQRCode-img" src="https://cdn.ideamake.cn/sw-pc/images/sw_wx_QR_code-sider.jpeg">
              <div class="title">添加微信</div>
              <div class="text">专属客服将为您介绍更多产品资讯</div>
            </div>
            <div class="nav-item-li" onclick="window._hmt.push(['_trackEvent', 'button','click', '右下角导航栏微信咨询按钮']);" slot="reference">
              <div class="nav-item-content">
                <img class="nav-item-img" src="https://cdn.ideamake.cn/sw-pc/images/bottom-icon-two.png">
              </div>
              <img class="nav-item-img hover" src="https://cdn.ideamake.cn/sw-pc/images/bottom-active-two.png">
            </div>
          </el-popover>
          微信咨询
        </div>
        
        <div class="nav-content">
          <el-popover
            placement="right"
            popper-class="wxQRCode-popper"
            trigger="hover">
            <div class="popover-box-two">
              <div class="title">400-8075-618</div>
              <div class="text">欢迎拨打电话咨询</div>
            </div>
            <div class="nav-item-li" onclick="window._hmt.push(['_trackEvent', 'button','click', '右下角导航栏服务热线按钮']);" slot="reference">
              <div class="nav-item-content">
                <img class="nav-item-img" src="https://cdn.ideamake.cn/sw-pc/images/bottom-icon-three.png">
              </div>
              <img class="nav-item-img hover" src="https://cdn.ideamake.cn/sw-pc/images/bottom-active-three.png">
            </div>
          </el-popover>
          服务热线
        </div>
      </div>
    </div>
    <div class="back-top">
      <back-to-top :visibility-height="300" :back-position="50" transition-name="fade" />
    </div>
  </div>
</template>
<script>
import { swTrackerClickReport } from '@/tracker.js'
import BackToTop from '@/components/BackToTop.vue'
export default {
  name: 'RightNav',
  components: {
    BackToTop,
  },
  data () {
    return {
      pathName: '思为科技首页'
    }
  },
  watch: {
    '$route.path' () {
      this.pathName = this.$route.name
    },
  },
  created () {
    this.pathName = this.$route.name
  },
  methods: {
    onConsult (){
      swTrackerClickReport('sw_pc_free_trial', '侧边导航-咨询', this.pathName)
      this.$emit("onConsult");
    },
    // 跳转立即体验
    goExperience() {
      this.$router.push({ path: '/reservation' })
    },
    // 跳转合作伙伴
    goPartner() {
      this.$router.push({ path: '/partner' })
    },
    // 跳转预约演示
    goReservation() {
      window._hmt.push(['_trackEvent', 'button','click', '右下角导航栏预约演示按钮']);
      this.$router.push({ name: 'reservation',params:{type: 1} })
    },
  }
}
</script>
<style lang="scss" scoped>
.right-nav {
  position: fixed;
  width: 60px;
  right: 25px;
  bottom: 30px;
  z-index: 1001;
  .nav-list{
    .nav-item{
      width: 60px;
      padding: 14px;
      cursor: pointer;
      box-sizing: border-box;
      background: #FFFFFF;
      line-height: 18px;
      font-size: 12px;
      box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.03), 0px 15px 35px -2px rgba(0,0,0,0.05);
      border-radius: 2px 0px 0px 2px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      margin-bottom: 12px;
      color: #878A90;
      .nav-item-img{
        width: 23px;
        height: 23px;
        &.hover{
          display: none;
        }
      }
      &.blue{
        background: #3777F6;
        color: #FFFFFF;
        &:hover{
          background: #5F92F8;
        }
      }
      &.hover:hover{
        color: #3777F6;
        .nav-item-img{
          display: none;
          &.hover{
            display:block;
          }
        }
      }
      &.active{
        padding: 8px 8px 22px;
        .nav-item-li{
          width: 50px;
          height: 50px;
          .nav-item-img.hover{
            display: none;
          }
          &:hover{
            color: #3777F6;
            background-color: #3777F6;
            .nav-item-img{
              display: none;
              &.hover{
                display:block;
              }
            }
          }
        }
      }
      .nav-item-li{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        align-items:center;
      }
    }
  }
  .back-top{
    width: 48px;
    height: 48px;
  }
}
// 响应式媒体查询
@media screen and (max-width: 980px) {
  .right-nav {
    bottom: 0.8rem;
    .nav-list {
      display: none;
    }
  }
}
</style>