<template>
  <div class="cases-item case">
   
    <h3 class="title">{{ title }}</h3>
    <div class="container">
      <div class="btn-group">
        <div
          :class="{ active: index === active }"
          v-for="(item, index) in btnGroup"
          :key="item"
          @click="btnGroupChange(index,item)"
        >
          {{ item }}
        </div>
      </div>

      <el-carousel
        :interval="3000"
        arrow="always"
        :height="showNumber === 6 ? '950px' : '520px'"
      >
        <el-carousel-item v-for="(data, index) in caseData" :key="'ck' + index">
          <div class="list">
            <div class="list-box" v-for="item in data" :key="item.developer">
              <div class="item-text">
                <div class="img-box">
                  <img
                    :class="['cases', { active: item.type === 'vision' }]"
                    :src="item.cases"
                    :alt="item.developer"
                  />
                </div>
                <h4 class="title">{{ item.title }}</h4>
                <div class="statistics" v-if="item.count">
                  <div class="statistics-number">
                    <h4>{{ item.count }}</h4>
                    <p>总访问量</p>
                  </div>
                  <div class="statistics-number">
                    <h4>{{ item.number }}</h4>
                    <p>总访问人数</p>
                  </div>
                  <div class="statistics-number">
                    <h4>{{ item.share }}</h4>
                    <p>
                      {{
                        item.type === "digit"
                          ? "小程序分享数"
                          : "单日最高访问"
                      }}
                    </p>
                  </div>
                </div>
                <div v-else class="desc">
                  <h5>{{ item.sub }}</h5>
                  <p>{{ item.des }}</p>
                </div>
              </div>
              <div class="item-code">
                <div class="img-box">
                  <img class="code" :src="item.logo" :alt="item.developer" />
                  <p class="scan">扫码体验</p>
                </div>

                <div
                  class="btn"
                  :style="{
                    visibility: 'hidden',
                  }"
                >
                  点击查看详情
                </div>
                <h4 class="title">{{ item.title }}</h4>
                <div class="text">
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>

function getData(list = [], num = 6) {
  const data = [...list];

  const len = data.length; // 总长度
  const count = Math.trunc(len / num); // 循环次数
  const residue = len % num; // 剩余个数
  let temp = [];
  for (let i = 0; i < count; i++) {
    temp.push(data.splice(0, num));
  }
  if (residue) {
    temp.push(data.splice(0, residue));
  }
  return temp;
}
export default {
  name: "CasesItem",
  props: {
    title: {
      type: String,
      default: () => "客户案例",
    },
    showNumber: {
      type: Number,
      default: () => 6,
    },
  },
  computed: {
    caseData() {
      let data = [];
      if (this.active === 0) {
        // 返回全部
        const temp = [...this.digit, ...this.vision];
        data = getData(temp, this.showNumber);
      } else if (this.active === 1) {
        // 返回营销云
        data = getData(this.digit, this.showNumber);
      } else if (this.active === 2) {
        // 返回案场
        data = getData(this.vision, this.showNumber);
      }
      return data;
    },
  },
  data() {
    return {
      btnGroup: ["全部", "营销云", "智慧案场"],
      active: 0,
      digit: [
        {
          developer: "招商蛇口",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/zs-code.png",
          title: "招商蛇口·招商好房加强版",
          text: "招商蛇口是中国领先的城市和园区综合开发运营服务商，作为招商局集团旗下城市综合开发运营板块的旗舰企业，坚持以“产融创新，低碳先锋，经营稳健”为特色，为城市发展与产业升级提供综合性的解决方案，致力于成为“美好生活承载者”。",
          count: "8200万+",
          number: "255万+",
          share: "140万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/zs-bg.jpeg",
          type: "digit",
        },
        {
          developer: "越秀房宝",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/yx-code.png",
          title: "越秀地产·越秀房宝",
          text: "越秀地产始终以客户为中心，坚持“品质、责任、创新、共赢”品牌核心价值，打造拥有“好产品、好服务、好品牌、好团队”的四好企业，致力于成为城市美好生活创领者。",
          count: "4000万+",
          number: "360万+",
          share: "320万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/yx-bg.jpeg",
          type: "digit",
        },
        {
          developer: "中海幸福家",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/zh-code.png",
          title: "中海地产·中海幸福家",
          text: "中海拥有43年房地产开发与不动产运营管理经验，业务遍布港澳及内地80余个城市及美国、英国、澳大利亚、新加坡等多个国家和地区，具备行业领先的设计、开发、建造、运营、物业服务等全产业链整合联动能力",
          count: "4000万+",
          number: "360万+",
          share: "320万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/zh-bg.jpeg",
          type: "digit",
        },
        {
          developer: "建好房",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/jf-code.png",
          title: "建发集团·建好房",
          text: "建发在中国改革开放的大背景下，紧紧围绕服务国家战略、服务地方发展、服务民生福祉、建立健全现代企业制度等一系列重大命题，积极探索国有企业高质量发展之路。",
          count: "170万+",
          number: "20万+",
          share: "12万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/jf-bg.jpeg",
          type: "digit",
        },
        {
          developer: "幸福云",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/rc-code.png",
          title: "融创中国·幸福云",
          text: "融创中国成立于2003年，以“至臻·致远”为品牌理念，致力于通过高品质的产品与服务，整合优质资源，为中国家庭提供美好生活场景与服务，成为中国“美好城市共建者”。",
          count: "1800万+",
          number: "150万+",
          share: "500万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/rc-bg.jpeg",
          type: "digit",
        },
        {
          developer: "金科美好+",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/jk-code.png",
          title: "金科集团·金科美好+",
          text: "金科集团成立于1998年，经20余年创新发展，形成了“四位一体、生态协同”的总体布局，即：精耕地产主业，做强智慧服务，做优科技产业，做实商旅康养，并在此基础上进一步整合产业链生态圈。",
          count: "5500万+",
          number: "105万+",
          share: "45万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/jk-bg.jpeg",
          type: "digit",
        },

   

        {
          developer: "保利集团·浙保云和+",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/bl-code.png",
          title: "浙保云和+",
          text: "保利致力于“打造具有卓越竞争力的不动产生态平台”，以不动产投资开发、资产运营、资本运作为基础，提供基于行业生态系统的综合服务。2022年，保利发展控股位居《福布斯》世界排名第190位，行业领导品牌TOP3。",
          count: "80万+",
          number: "5万+",
          share: "3万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/bl-bg.jpeg",
          type: "digit",
        },
        {
          developer: "华侨城·置业侨选",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/hqc-code.png",
          title: "置业侨选",
          text: "华侨城集团已形成以文化、旅游、房地产、电子科技为主业的发展格局，是首批国家级文化产业示范园区、全国文化企业30强、中国旅游集团20强、中国房企品牌20强，业务遍及全国百余座城市以及全球多个国家和地区。",
          count: "30万+",
          number: "2万+",
          share: "6.2万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/hqc-bg.jpeg",
          type: "digit",
        },
        {
          developer: "北科建·北科建欣悦家",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/bjk-code.png",
          title: "北科建欣悦家",
          text: "北科建集团成立于1999年，是以科技地产为龙头、商业地产、住宅地产协同发展，按照现代企业制度组建的大型国有企业集团。",
          count: "20万+",
          number: "2万+",
          share: "1万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/digit2/bjk-bg.jpeg",
          type: "digit",
        },
      ],
      vision: [
        {
          developer: "中海观云府",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/zh-code.png",
          title: "中海观云府",
          text: "中海观云府是中海在广州海珠区开发的高端住宅项目，其精美尊贵的外立面特征，在思为科技数字沙盘技术的加持下，体现得淋漓尽致。",
          count: "23万+",
          number: "4万+",
          share: "2000+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/zh-bg.png",
          type: "vision",
        },
        {
          developer: "合颂兰亭花园",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/hs-code.png",
          title: "合颂兰亭花园",
          text: "合颂兰亭花园，以舒适生活为价值导向，打造昆山首个6A城市湖居范本，微沙盘展示湖畔风光，使人如同身临其境。",
          count: "23万+",
          number: "5万+",
          share: "1000+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/hs-bg.jpeg",
          type: "vision",
        },
        {
          developer: "深哈产业园",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/sh-code.png",
          title: "深哈产业园",
          text: "以深圳粤港澳大湾区为背景，深圳产业发展优势为契机，与哈尔滨产业优势结合，打造对标世界一流园区。思为科技通过清晰呈现多种户型，直观展示户型及落位；通过智慧销控系统，实时体现招商动态，方便招商员及客户了解入驻企业信息",
          count: "1万+",
          number: "2000+",
          share: "1000+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/sh-bg.png",
          type: "vision",
        },
        {
          developer: "绿地云景",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/yj-code.png",
          title: "绿地云景",
          text: "绿地云景是绿地集团在苏州开发的超高层地标式摩天地标项目，集合商业、办公、酒店、高端住宅、景观餐厅等多功能。项目定位高端，在展现精度和建模效果上要求很高，因此思为通过定制化UI设计和建模演绎，着重呈现品质感和尊贵感。置业顾问可通过Ipad移动案场在商务场合做推介演示，又可通过微沙盘让客户随时带走项目信息，随时查看。",
          count: "50万+",
          number: "20万+",
          share: "4万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/yj-bg.png",
          type: "vision",
        },
        {
          developer: "阿那亚",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/aly-code.png",
          title: "阿那亚",
          text: "思为科技为阿那亚公寓项目提供行业内独家专利技术，为项目嵌入虚拟漫游随走随停，且可随时切换观赏角度的iPad移动案场，客户随手即可带走楼盘信息，提高项目传播影响力。",
          count: "45万+",
          number: "8万+",
          share: "1万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/aly-bg.png",
          type: "vision",
        },
        {
          developer: "碧桂园星钻",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/bgy-code.png",
          title: "碧桂园星钻",
          text: "作为碧桂园的长期战略合作伙伴，思为科技已为碧桂园开发近百个移动案场，代表项目有森林城市、星钻、十里金滩、潼湖科技小镇等。",
          count: "100万+",
          number: "20万+",
          share: "1万+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/bgy-bg.png",
          type: "vision",
        },

        {
          developer: "华润瑞府",
          logo: "https://cdn.ideamake.cn/086d85ebc0f112c63aafc86673c4f485/20221226_153914a101bb7b5a7b4aa5b953343e592a2d07.png",
          title: "华润瑞府",
          text: "傲居深圳湾核心地段，坐拥120万方综合体九大顶级业态，占据得天独厚的自然和人文资源，通过思为科技微沙盘呈现奢华户型，打造深圳乃至全国豪宅中的稀缺资产。",
          count: "100万+",
          number: "20万+",
          share: "1万+",
          cases: "https://cdn.ideamake.cn/086d85ebc0f112c63aafc86673c4f485/20221226_153840cbce54b4737b4cfab55ec1de896665a2.png",
          type: "vision",
        },
        {
          developer: "万科重庆天地",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/td-code.png",
          title: "万科重庆天地",
          text: "思为科技为万科提供的项目覆盖全国十余个省级区域。其中万科·重庆天地微沙盘总访问量突破四十万，为项目提供出色的异地传播、异地推荐途径。",
          count: "40万+",
          number: "5万+",
          share: "7000+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/td-bg.png",
          type: "vision",
        },

        {
          developer: "凯德重庆来福士",
          logo: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/lfc-code.png",
          title: "凯德重庆来福士",
          text: "重庆来福士是凯德集团在华开发最大的来福士项目，思为智慧案场完美呈现重庆来福士的优势地理位置和优质配套资源。",
          count: "4万+",
          number: "9000+",
          share: "6000+",
          cases: "https://cdn.ideamake.cn/sw-pc/images/cases/vision2/lfc-bg.jpeg",
          type: "vision",
        },
      ],
    };
  },
  methods: {
    btnGroupChange (index,title) {
      window._hmt.push(['_trackEvent', 'button','click', `成功案例客户案例轮播头部${title}按钮`]);
      this.active = index
    }
  }
};
</script>

<style lang="scss" scoped>
.cases-item {
  width: 100%;
  min-height: 544px;
  padding: 80px 0 80px;
  box-sizing: border-box;
  text-align: center;
  background: #f5f9fc;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #2d2e30;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    .btn-group {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
      div {
        width: 100px;
        font-size: 14px;
        font-weight: 400;
        color: #3777f6;
        padding: 0 20px;
        height: 42px;
        line-height: 42px;
        border-radius: 50px;
        background: rgba(55, 119, 246, 0.1);
        margin-right: 30px;
        cursor: pointer;
      }
      .active {
        background: #3777f6;
        color: #fff;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 80px;
      &-box {
        position: relative;
        width: 334px;
        height: 430px;
        background: #fff;
        border-radius: 5px;
        text-align: left;
        margin-bottom: 20px;
        overflow: hidden;

        .item-text {
          .img-box {
            height: 280px;
            overflow: hidden;
          }
          .cases {
            border-radius: 8px;
            width: 100%;
            &.active {
              height: 280px;
            }
          }
          .title {
            width: 100%;
            padding: 16px;
            font-size: 18px;
            font-weight: bold;
            color: #3777f6;
            line-height: 20px;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .statistics {
            padding: 16px;
            display: flex;

            &-number {
              font-size: 14px;
              color: #999;

              &:last-child {
                margin-right: 0px;
              }
              margin-right: 20px;
              h4 {
                font-size: 16px;
                color: #3777f6;
                margin-bottom: 6px;
              }
            }
          }
        }
        .desc {
          padding: 16px;
          font-size: 14px;
          color: #999;
          h5 {
            font-size: 16px;
            color: #3777f6;
            margin-bottom: 6px;
          }
        }
        .item-code {
          display: none;
          position: absolute;
          width: 100%;
          height: 430px;
          left: 0;
          top: 0;
          text-align: center;
          box-sizing: border-box;
          .img-box {
            height: 170px;
            margin-top:30px
          }
          .code {
            width: 130px;
            height: 130px;
            border-radius: 8px;
            margin-top: 30px;
          }
          .title {
            width: 100%;
            padding: 16px;
            font-size: 18px;
            font-weight: 500;
            color: #2d2e30;
            line-height: 20px;
            text-align: left;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: #fff;
          }
          .scan {
            font-size: 14px;
            margin-top: 20px;
            color: #fff;
          }
          .btn {
            line-height: 34px;
            font-size: 14px;
            color: #2860f3;
            text-align: center;
            margin: 9px auto;
            background: #fff;
            width: 136px;
            border-radius: 18px;
            margin-bottom: 38px;
          }
          .text {
            color: #5a5c60;
            line-height: 26px;
            height: 96px;
            padding: 10px 16px 16px 16px;
            text-align: justify;
            background: #fff;
            transition: height 2s;
            overflow: hidden;
            p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              overflow: hidden;
            }
          }
        }
      }
      &-box:hover {
        border-radius: 8px;
       
        box-shadow: 0px 4px 12px 0px rgba(155,165,192,0.25);
        .item-code {
          display: block;
          background: rgba(0, 0, 0, 0.6);
          .text {
            height: 96px;
          }
        }
      }
    }
    ::v-deep .el-carousel__button {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin: 0 20px;
      border: 3px solid #3777f6;
      background: #fff;
      position: relative;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      display: inline-flex;
    }

    ::v-deep .is-active .el-carousel__button:after {
      content: "";
      display: block;
      position: absolute;
      left: -12px;
      top: -12px;
      width: 30px;
      height: 30px;
      background: rgba(21, 83, 245, 0.5);
      border-radius: 50%;
    }
    ::v-deep .el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 50px;
      width: 50px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(155, 165, 192, 0.25);
      color: #878a90;
      position: absolute;
      top: 44%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      font-size: 24px;
    }
  }
}

// 移动端布局
@import "@/views/cases/min-cases";
</style>