<template>
  <div class="solution">
    <!-- 页面顶部banner -->
    <solution-banner />
    <!-- 页面主体内容 -->
    <solution-panel />
     <!-- 客户公司 -->
    <!-- <customer-recognition></customer-recognition> -->
     <!-- 市场占比 -->
    <digital-marketing></digital-marketing>

  </div>
</template>

<script>
import SolutionBanner from "./components/Banner.vue"
import SolutionPanel from "./components/Panel.vue";
// import customerRecognition from '@/components/CustomerRecognition.vue'
import digitalMarketing from '@/components/DigitalMarketing.vue'
import swTracker from '@/mixins/tracker.js'
import utils from '@/utils'

export default {
  name: "SolutionView",
  mixins: [swTracker],
  components:{
    SolutionBanner,
    SolutionPanel,
    // customerRecognition,
    digitalMarketing,
    // marketingUpgrade,
  },
  data () {
    return {
      pathUrl: '/solution',
      pathName: '',
    }
  },
  watch: {
    '$route.path' () {
      this.pathUrl = utils.changeRoutePath(this.$route.path)
      this.pathName = this.$route.name
    },
  },
  mounted () {
    this.pathUrl = utils.changeRoutePath(this.$route.path)
    this.pathName = this.$route.name
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
// 移动端布局
@import "@/views/solution/min-solution";
</style>
